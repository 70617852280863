import { Link, Typography } from '@mui/material';
import React from 'react';
import '../../App.css';

export const Copyright = (props) => {
    return (
        <>
            <Typography variant="body2" color="text.secondary" align="center" {...props} >
                {`Copyright © Auris ${new Date().getFullYear()}`}
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center" >
                <Link color="inherit" target="_blank" href="https://voikers.com/">
                    Voikers
                </Link>
                {' & '}
                <Link color="inherit" target="_blank" href="https://avantgardeit.es/">
                    Avantgarde IT
                </Link>
            </Typography>
        </>
    )
}
