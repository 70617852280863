import { useState } from 'react';
import './AurisMultiselect.css';
import { ReactComponent as IconAngleDown } from '../../assets/img/icon-angle-down.svg';

export default function AurisMultiselect({ name, title, selectedItems, setSelectedItems, availableItems, renderValueFn }) {
  renderValueFn = renderValueFn || function (value) { return (value) };
  const [isExpanded, setIsExpanded] = useState(false);

  function expandDropdown() {
    setIsExpanded(true);
  }

  function collapseDropdown() {
    setIsExpanded(false);
  }

  function toggleSelectItem(item) {
    return (e) => {
      if (isItemSelected(item)) {
        setSelectedItems(selectedItems.filter(selectedItem => item !== selectedItem))
      } else {
        setSelectedItems([...selectedItems, item])
      }
    }
  }
  function isItemSelected(item) {
    return selectedItems.includes(item);
  }

  return (
    <div className={`auris-select ${isExpanded ? '--is-expanded' : ''}`} tabIndex={0} role="listbox" onFocus={expandDropdown} onBlur={collapseDropdown}>
      <div className="auris-select__input">
        <div className="auris-select__input-selected">
          <SelectedItems selectedItems={selectedItems} renderValueFn={renderValueFn}></SelectedItems>
        </div>
        <div className="auris-select__input-placeholder"><span className="auris-select__input-placeholder-title">{title}</span><IconAngleDown></IconAngleDown></div>
      </div>
      <div className="auris-select__dropdown">
        {availableItems.map(item => (
          <div className={`auris-select__dropdown-item ${isItemSelected(item) ? '--is-selected' : ''}`} onClick={toggleSelectItem(item)}>{renderValueFn(item)}</div>
        ))}
      </div>
    </div>
  )
}


function SelectedItems({ selectedItems, renderValueFn }) {
  switch (selectedItems.length) {
    case 0:
      return (null);
    case 1:
    case 2:
      return selectedItems.map(item => (<div className="auris-select__input-selected-item">{renderValueFn(item)}</div>));
    default:
      return selectedItems
        .filter((_, index) => index < 3)
        .map((item, index) => {
          return index === 2
            ? (<div className="auris-select__input-selected-item">(y {selectedItems.length - index} más)</div>)
            : (<div className="auris-select__input-selected-item">{renderValueFn(item)}</div>)
        });
  }
}