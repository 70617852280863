import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import { getAllVoices } from '../../adapters/api';
import { ReactComponent as VolumeLogo } from '../../assets/img/volume-last.svg';
import { translateAccents } from '../../utils/utils';
import Star from '../../assets/img/star.svg';
import AurisMultiselect from '../../components/aurisMultiselect/AurisMultiselect';
import FlagImage from '../../components/flagImage/FlagImage';
import AurisPlayPause from '../../components/aurisPlayPause/AurisPlayPause';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };


  return (
    <HelmetProvider>
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <Helmet>
        <title>Auris Audio - Voces</title>
        <meta name="description" content="Prueba nuestras voces sintéticas" />
      </Helmet>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
    </HelmetProvider>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export const Voices = () => {
  const [page, setPage] = useState(0);
  const [voices, setVoices] = useState([]);
  const [availableAccents, setAvailableAccents] = useState([]);
  const [availableGenders, setAvailableGenders] = useState([]);
  const [selectedAccents, setSelectedAccents] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState({ key: 'name', sort: 'asc' })

  useEffect(async () => {
    const { data } = await getAllVoices();
    setVoices(data.voices);
  }, [])

  useEffect(() => {
    setFilteredRows(voices
      .map(({ _id, alias, gender, accent, audioSample, type, foreign }) => ({
        id: _id, name: alias, gender, accent, audioSample, type, foreign
      }))
      .filter(row => selectedAccents.length === 0 || selectedAccents.includes(row.accent))
      .filter(row => selectedGenders.length === 0 || selectedGenders.includes(row.gender))
      .sort((a, b) =>
        orderBy.sort === 'asc'
          ? (a[orderBy.key] < b[orderBy.key] ? -1 : 1)
          : (a[orderBy.key] > b[orderBy.key] ? -1 : 1)
      )
    );

    setAvailableAccents(voices.map(({ accent }) => accent).filter((accent, index, accentList) => accentList.indexOf(accent) === index));
    setAvailableGenders(voices.map(({ gender }) => gender).filter((gender, index, genderList) => genderList.indexOf(gender) === index));
  }, [voices, selectedAccents, selectedGenders, orderBy])

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#F0F0F0',
      borderColor: 'dedac9',
      color: theme.palette.common.black,
      fontWeight: 600
    },
    [`&.${tableCellClasses.body}`]: {
      borderRadius: '25px',
      fontSize: 14,
    },
  }));

  function handleOrderBy(key) {
    return () => {
      if (orderBy.key === key) {
        setOrderBy({
          key,
          sort: orderBy.sort === 'asc' ? 'desc' : 'asc'
        });
      } else {
        setOrderBy({
          key,
          sort: 'asc'
        });
      }
    }
  }

  function getSortingClassesForKey(key) {
    if (orderBy.key === key) {
      return `--allow-sorting --is-sorted-${orderBy.sort}`;
    } else {
      return '--allow-sorting';
    }
  }

  const tableFilters = [
    { name: 'accent', title: 'Filtrar por acento', selectedItems: selectedAccents, setSelectedItems: setSelectedAccents, availableItems: availableAccents, renderValueFn: (value) => (<><FlagImage flag={value} accent={value}></FlagImage>&nbsp;{value}</>) },
    { name: 'gender', title: 'Filtrar por género', selectedItems: selectedGenders, setSelectedItems: setSelectedGenders, availableItems: availableGenders, renderValueFn: (value) => value == "male" ? "Masculino" : "Femenino" }
  ]

  return (
    <TableContainer className="customTableDiv" component={Paper}>
      <div className="tableTitle">
        <Typography className="menuTitle"><VolumeLogo /> Voces</Typography>
        <Typography className="subTitle" style={{ maxWidth: 500, marginBottom: 50 }}>Explora entre nuestra colección de voces AI y elige la que mejor se adapte a tu contenido. Usa los filtros para afinar la búsqueda. </Typography>
      </div>
      <div style={{ display: 'flex', alignItems: 'flex-end', paddingLeft: '20px' }}>
        {tableFilters.map(filterProps => (
          <AurisMultiselect {...filterProps}></AurisMultiselect>
        ))}
      </div>
      <Table className="customTable --with-filters" aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <StyledTableCell className={`roundedHeaderLeft tableHeader ${getSortingClassesForKey('name')}`} onClick={handleOrderBy('name')}>Nombre</StyledTableCell>
            <StyledTableCell className={`tableHeader ${getSortingClassesForKey('gender')}`} onClick={handleOrderBy('gender')}>Género</StyledTableCell>
            <StyledTableCell className={`tableHeader ${getSortingClassesForKey('accent')}`} onClick={handleOrderBy('accent')}>Acento</StyledTableCell>
            <StyledTableCell className={`tableHeader ${getSortingClassesForKey('type')}`} onClick={handleOrderBy('type')}>Premium</StyledTableCell>
            {/* <StyledTableCell className={`roundedHeaderRight tableHeader`} ></StyledTableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : filteredRows
          ).map((row) => (
            <TableRow key={row.name}>
              <TableCell align="left" style={{ textTransform: 'capitalize' }} className="tableHeader auris-table__cell--no-left-padding">
                <AurisPlayPause style={{ justifyContent: 'flex-start', minWidth: '55px' }} soundUrl={row.audioSample} height={32} width={32}></AurisPlayPause>
                {row.name}
              </TableCell>
              <TableCell align="left" className="tableHeader">
                {row.gender == "male" ? "Masculino" : "Femenino"}
              </TableCell>
              <Tooltip title={translateAccents(row.accent)} placement="left" TransitionComponent={Zoom}>
                <TableCell style={{ textTransform: 'capitalize', cursor: "pointer" }} align="left" className="tableHeader">
                  <FlagImage flag={row.accent} accent={row.accent}></FlagImage>
                </TableCell>
              </Tooltip>
              <TableCell><img style={{ display: row.type === 'premium' ? '' : 'none' }} src={Star} width={'20px'} height={'20px'} alt="premium" /></TableCell>
            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={5}
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}