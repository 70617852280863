import React, { useRef, useState, useEffect } from 'react';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { getAllVoices, createSpeech } from '../../adapters/api';
import { Typography } from '@mui/material';
import { translateAccents } from '../../utils/utils';
import FlagImage from '../../components/flagImage/FlagImage';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import AurisPlayPause from '../aurisPlayPause/AurisPlayPause';
const Swal = require('sweetalert2');


export const TextArea = (props) => {
    const message = useRef(null);
    const [voices, setVoices] = useState([]);
    const [selectedVoice, setVoice] = useState(props.sentVoice ? props.sentVoice : '');
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [lastVoicePlayed, setLastVoicePlayed] = useState(null);
    const [lastMessagePlayed, setLastMessagePlayed] = useState(null);
    const [lastAudioUrlPlayed, setLastAudioUrlPlayed] = useState(null);

    const recalculate = (e) => {
        setCount(e.target.value.length);
    }

    useEffect(() => {
        async function fetchData() {
            const voices = await getAllVoices();
            setVoices(voices.data.voices);
        }
        fetchData();
      }, [])

    async function getSoundUrl() {
        setLoading(true);
        try {
            const isTheSameVoiceAsBefore = lastVoicePlayed === selectedVoice;
            const isTheSameMessageAsBefore = lastMessagePlayed ===  message.current.value;
            if(lastAudioUrlPlayed !== null && (isTheSameVoiceAsBefore && isTheSameMessageAsBefore)) {
                setLoading(false);
                return lastAudioUrlPlayed;
            } else {
                const {data} = await createSpeech(message.current.value, selectedVoice);
                setLastMessagePlayed( message.current.value);
                setLastVoicePlayed(selectedVoice);
                setLastAudioUrlPlayed(data.audio.default.url.replace('https', 'http')) // para desarrollo, quitar replace en PRO
                setLoading(false);
                return data.audio.default.url.replace('https', 'http');
            }
            
        } catch(error) {
            setLoading(false);
            Swal.fire({
                title: 'Error',
                text: 'Hubo un error durante la creación del audio',
                icon: 'error',
                confirmButtonText: 'Ok'
              });
        }
        
    }

    const handleChange = (event) => {
        setVoice(event.target.value);
    };

    return (
        <div>
            <Grid container className="landing-textarea"
                alignItems="center"
                justifyContent="center"
                direction="column">
                {props.landingMode &&
                    <Grid item xs={12}>
                        <FormControl variant="outlined" style={{ width: "100%" }} >
                            <InputLabel style={{ marginLeft: 5, backgroundColor: "white", padding: 5 }} id="label-placeholder-player">Elige una voz</InputLabel>
                            <Select
                                className="voiceSelect"
                                value={selectedVoice}
                                onChange={handleChange}
                            >
                                <MenuItem disabled value="">
                                    <em>Elige una voz</em>
                                </MenuItem>
                                {voices.map((voice) => (
                                    <MenuItem
                                        key={voice.alias}
                                        value={voice.alias}
                                    >
                                        <Tooltip title={translateAccents(voice.accent) ? translateAccents(voice.accent):' '} placement="right">
                                            <span style={{ textTransform: 'capitalize' }}>
                                                {voice.alias} - {voice.gender === 'female' ? 'Femenino' : 'Masculino'} - 
                                                <FlagImage 
                                                    flag={voice.accent}
                                                    width={'20px'} height={'20px'}
                                                    style={{ marginTop: '3px', marginLeft: '5px', position: 'absolute' }}>
                                                </FlagImage>
                                            </span>
                                        </Tooltip>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>}
                <div className='relative'>
                    <Grid item xs={12} className='relative'>
                        <TextareaAutosize
                            className="textArea"
                            aria-label="minimum height"
                            minRows={3}
                            maxLength={100}
                            ref={message}
                            placeholder="Escribe aquí tu texto y dale al botón de escuchar. Así de simple..."
                            onChange={recalculate}>
                        </TextareaAutosize>
                        <div className="playBtn">
                            <AurisPlayPause height={32} width={32} getSoundUrl={getSoundUrl}></AurisPlayPause>
                        </div>
                    </Grid>
                    <Typography className="characterCount">
                        {count} / 100
                    </Typography>
                </div>
            </Grid>
            <LoadingSpinner isLoading={loading}/>
        </div>

    )
}