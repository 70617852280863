import {
  Box,
  Link,
  Grid,
  Button,
  Checkbox,
  TextField,
  Container,
  Typography,
  CssBaseline,
  FormControlLabel,
} from '@mui/material';
import React from 'react';
import '../../App.css'
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/img/logo.png'
import { login, findOneUser } from '../../adapters/api';
import { Copyright } from '../../components/footer/copyright';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Swal = require('sweetalert2');
const theme = createTheme();

export const Login = () => {
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      const res = await login(data.get('email'), data.get('password'));
      if (res) {
        if (res.data.token) {
          localStorage.setItem('auth-token', res.data.token);
          const response = await findOneUser(data.get('email'));
          if (response) {
            localStorage.setItem('username', response.data.user.firstName + " " + response.data.user.lastName);
            localStorage.setItem('tier', response.data.user.tier)
            localStorage.setItem('email', response.data.user.email)
            localStorage.setItem('remainingWords', response.data.user.remainingWords)
          }
          const route = response.data.user.tier === -1 ? '/tiers' : '/dashboard/audios';
          navigate(route);
        }
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Error al intentar hacer login: ' + error.response.data.message,
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  };

  return (
    <HelmetProvider>
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Auris Audio - Login</title>
        <meta name="description" content="Entra en Auris con tu cuenta y sácale el máximo provecho" />
      </Helmet>
      <div className='wrapper'>
        <div className='login'>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 8
              }}
            >
              <Typography component="h1" variant="h5" className="title">
                <Link href="/">
                  <img src={Logo} width={"138px"} height={"69px"} alt="logo" />
                </Link>
              </Typography>
              <Box
                noValidate
                component="form"
                onSubmit={handleSubmit}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: 1
                }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Correo electrónico"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <FormControlLabel
                  control={<Checkbox value="remember" defaultChecked
                    sx={{
                      color: '#fb4859',
                      '&.Mui-checked': {
                        color: '#fb4859',
                      },
                    }} className="checkbox" />}
                  label="Recuérdame"
                />
                <Button
                  className="red-button"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 3 }}
                >
                  Entrar
                </Button>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item sx={{ textAlign: 'center' }} xs={6}>
                    <Link href="/resetPassword" variant="body2">
                      ¿Olvidaste tu contraseña?
                    </Link>
                  </Grid>
                  <Grid item sx={{ textAlign: 'center' }} xs={6}>
                    <Link href="/register" variant="body2">
                      ¿No tienes cuenta? Regístrate
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 1 }} />
          </Container>
        </div>
      </div>
    </ThemeProvider>
    </HelmetProvider>
  );
}
