import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { getPlayersByUser, deletePlayer } from '../../adapters/api';
import {ReactComponent as PlayLogo} from '../../assets/img/play-last.svg';
import { getJWTEmail } from '../../utils/utils';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from "react-router-dom";
const Swal = require('sweetalert2')

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };  
  function createData(id, title, color, frame, watermark) {
    return { id, title, color, frame, watermark };
  }  
  export const AudioPlayer=()=> {
    const [page, setPage] = React.useState(0);
    const [rows, setRows] = React.useState([]);
    const [players, setPlayers] = useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [dialogDelete, setdialogDelete] = useState(false);
    const [idSelected, setId] = useState(null);    
    const populateRows = () => {
      const arr = [];
      for (let index = 0; index < players.length; index++) {
        arr.push(createData(players[index]._id, players[index].title, <div style={{backgroundColor: players[index].color, padding: '10px', width: '50px', borderRadius: '20px', textAlign: 'center' }}></div> , players[index].frame ? <CheckOutlinedIcon style={{fill: "green"}} /> : <CloseOutlinedIcon style={{fill: "red"}} />, players[index].watermark ?  <CloseOutlinedIcon style={{fill: "red"}}/> : <CheckOutlinedIcon style={{fill: "green"}} />));
       }
       setRows(arr.sort((a, b) => (a.gender < b.gender ? -1 : 1)));
    }
    useEffect(async () => {
      const data = await populateRows();
  }, [players])    
    useEffect(async () => {
      const userEmail = getJWTEmail()
      const user = {
        email: userEmail
      }
      const data = await getPlayersByUser(user);
      setPlayers(data.data.players);
  }, [])
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleDelete = (id) => {
      setdialogDelete(true);
      setId(id);
    }  
    const handleCancel = () => {
      setdialogDelete(false);
      setId(null);
    }  
    const handleOk = async () => {
      setdialogDelete(false);
      try {  
        const res = await deletePlayer(idSelected);
        if(res.status === 200){
          Swal.fire({
            title: 'Éxito',
            text: 'Audioplayer borrado satisfactoriamente',
            icon: 'success',
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            } 
          })
          setId(null);
        }
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Hubo un error durante el borrado del audioplayer',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }  
    }
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: '#F0F0F0',
          borderColor: '#000',
          color: theme.palette.common.black,
          fontWeight: 600
        },
        [`&.${tableCellClasses.body}`]: {
          borderRadius: '25px',
          fontSize: 14,
        },
      }));  
    return (
      <TableContainer component={Paper} className="customTableDiv">
        <div className="tableTitle">
        <Typography className="menuTitle" ><PlayLogo /> Mis audio players</Typography>
        <Typography className="subTitle" style={{maxWidth: 500}}>Personaliza la apariencia del player a tu gusto. Hazlo tuyo y consigue que se integre a la perfección en tu web. ¡Pulsa en Crear Nuevo para comenzar o selecciona uno de los que ya hayas creado!</Typography>
        </div>
        <Button variant="contained" className="tableRedButton" href="/dashboard/audioplayer/editor">Crear nuevo</Button>
        <Table className="customTable" aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <StyledTableCell className="roundedHeaderLeft tableHeader">Título</StyledTableCell>
            <StyledTableCell className="tableHeader">Color</StyledTableCell>
            <StyledTableCell style={{textAlign: "center"}} className="tableHeader">¿Marco?</StyledTableCell>
            <StyledTableCell style={{textAlign: "center"}} className="tableHeader" >¿Marca de agua?</StyledTableCell>
            <StyledTableCell className="roundedHeaderRight tableHeader"></StyledTableCell>
          </TableRow>
        </TableHead>
          <TableBody>  
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <TableRow key={row.id}>
                <TableCell  className="tableHeader">
                  {row.title}
                </TableCell>
                <TableCell >
                  {row.color}
                </TableCell>
                <TableCell style={{textAlign: "center"}}  >
                  {row.frame}
                </TableCell>
                <TableCell style={{textAlign: "center"}}  >
                  {row.watermark}
                </TableCell>
                <TableCell>
                <div className="rowLinkDiv">
                  <IconButton component={Link} to={`editor/${row.id}`} state={{ update: true }}>
                    <EditIcon className="rowLink"  />
                  </IconButton >
                  <IconButton >
                  <DeleteIcon onClick={() => handleDelete(row.id)} style={{ color: "#26022F" }} />
                  </IconButton>
                </div>
                </TableCell>
              </TableRow>
            ))}  
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={5}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={dialogDelete}
      >
        <DialogTitle>Eliminar Audio Player</DialogTitle>
        <DialogContent >
          ¿Quiere eliminar este audiplayer?
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            No
          </Button>
          <Button onClick={handleOk}>Sí</Button>
        </DialogActions>
      </Dialog>
      </TableContainer>
    );
  }