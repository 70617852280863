import { 
    Grid,
    Button,
    Checkbox,
    Container,
    TextField,
    TextareaAutosize,
    FormControlLabel,
} from '@mui/material';
import React, { useState, useRef } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Header } from '../../components/header/index';
import { Footer } from '../../components/footer/index';
import { sendContactEmail } from '../../adapters/api';
import Swal from 'sweetalert2';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const theme = createTheme();
export const Contact = () => {
    const message = useRef(null);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [checkPrivacy, setPrivacy] = useState(false);
    const handleSubmit = async () => {
        if (checkPrivacy) {
            await sendContactEmail(email, name, message.current.value);
            Swal.fire({
                title: 'Éxito',
                text: 'Tu solicitud se ha realizado con éxito. En breve nos pondremos en contacto contigo.',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        }
    }
    const handlePrivacy = () => {
        setPrivacy(!checkPrivacy);
    }
    return (
        <HelmetProvider>
        <ThemeProvider theme={theme}>
            <Helmet>
                <title>Auris Audio - Contacto</title>
                <meta name="description" content="Estamos aquí para escucharte y ayudarte. Si tienes alguna duda o sugerencia, escríbenos y te responderemos lo antes posible." />
            </Helmet>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Header />
                <div className='bg-white'>
                    <div className='wrapper'>
                        <Grid container spacing={2} className='pt-dk-5'>
                            <Grid item xs={12} md={7} >
                                <div className="titleHome mb-1">
                                    Contáctanos
                                </div>
                                <div className='subtitleHome mb-2'>
                                    Estamos aquí para escucharte y ayudarte. Si tienes alguna duda o sugerencia, escríbenos y te responderemos lo antes posible.
                                </div>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <div className='contact-form'>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Nombre"
                                        name="nombre"
                                        autoComplete="name"
                                        onChange={(event) => setName(event.target.value)}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        name="email"
                                        autoComplete="email"
                                        onChange={(event) => setEmail(event.target.value)}
                                    />
                                    <TextareaAutosize
                                        ref={message}
                                        className='contact-textarea'
                                        aria-label="minimum height"
                                        maxLength={100}
                                        placeholder="Escribe aquí tu mensaje...">
                                    </TextareaAutosize>
                                    <Button onClick={handleSubmit} className='redButtonTrial no-center clearfix full-width mx-0 mt-2 correction'>Enviar</Button>
                                    <label className='checkbox mt-1 block'>
                                        <FormControlLabel
                                            label={<><span>Acepto las </span><a href='/privacy'>políticas de privacidad</a></>}
                                            control={
                                                <Checkbox
                                                    checked={checkPrivacy}
                                                    onChange={handlePrivacy}
                                                />
                                            }
                                        />
                                    </label>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Container>
            <Footer />
        </ThemeProvider>
        </HelmetProvider>
    )
}