import React, { useEffect } from 'react';
import '../../App.css';
import Logo from '../../assets/img/logo.png';
import { useLocation } from "react-router-dom";
import { refreshToken } from '../../adapters/api';

export const Header = () => {

    const location = useLocation();

    useEffect(async () => {

        try {
            await refreshToken();
          } 
          catch (error) {
            localStorage.clear();
          }

    },[]);

    const openMenu = () => {
        document.getElementById("sidenav").style.width = "100%";
    };

    const closeMenu = () => {
        document.getElementById("sidenav").style.width = "0";
    };

    const showMenu = () => {
        let result = true;
        const isUserLoged = localStorage.getItem("email") ?  true : false;
        if(isUserLoged && location.pathname === "/tiers") result = false;
        return result;
    }

    const isUserLoged = () => {
        return localStorage.getItem("email") ?  true : false;
    }

    return (
        <div className="header">
            <div className='wrapper'>
                <a href="/" className="titleHeader"><img src={Logo} alt="logo" /></a>
                <a className='sidenav-button' onClick={openMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </a>
                {
                    showMenu() &&

                    <div id="sidenav" className="sidenav">
                        <a className="closebtn" onClick={closeMenu}>&times;</a>
                        <a href="/voices">Voces</a>
                        {
                            isUserLoged() ? <a href="/dashboard/subscriptions">Precios</a> : <a href="/tiers">Precios</a>
                        }
                        <a href="/contact">Contacto</a>
                        <div className='header-buttons'>
                            {
                                isUserLoged() ?
                                <a className="redButtonOutline" href="/dashboard/audios">Ir al portal</a>
                                :
                                <div>
                                <a className="redButtonOutline" href="/login">Acceder</a>
                                <a className="headerRedButton" href="/register">Registrarse</a>
                                </div>
                            }
                        </div>            
                    </div>
                }
            </div>
        </div>
    )
}
