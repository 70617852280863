import './MySubscription.css'
import React from 'react';
import {
    Grid,
    Button
} from '@mui/material';
import {
    CalendarMonth as TypeIcon
} from '@mui/icons-material';
import { createPortalSession } from '../../adapters/api';

export default function MySubscription({ subscription, tier, audioCont, endPeriod, onCloseSubscription }) {

    const endUserPeriod = new Date(endPeriod);

    const getPrice = () => {
        let result = 0;
        if (tier.price) {
            const type = subscription.type;
            const price = tier.price.find((i) => { return type.includes(i.type) });
            result = price.amount;
        }
        return result;
    }

    const getType = () => {
        let result = "";
        if (subscription.type) {
            result = subscription.type.toUpperCase();
        }
        return result;
    }

    const navigateToBilling = async () => {
        const mail = localStorage.getItem('email');
        const responseSession = await createPortalSession(mail);
        window.location.href = responseSession.data.url;
    }

    return (
        
        <div className="auris-mySubscription">
            <Grid container>
                <Grid item xs={12} >
                    <div className="auris-mySubscription-title full-width center">
                        <span>Mi subscripción: </span>
                        <span style={{ color: "#e5a1d7" }}>{tier.title}</span>
                    </div>
                </Grid>
                <Grid item xs={12} >
                    <div className="auris-mySubscription-description">
                        {tier.description}
                    </div>
                </Grid>
                <div className="auris-mySubscription-body">
                    <Grid container style={{ textAlign: "center", display: "inline-block" }}>

                        <div className='auris-mySubscription-body-item'>
                            <div className="auris-mySubscription-body-title">
                                Precio mensual
                            </div>
                            <div>
                                <span className="auris-mySubscription-body-price">{getPrice()} €</span>
                            </div>
                        </div>

                        <div className='auris-mySubscription-body-item'>
                            <div className="auris-mySubscription-body-title">
                                Tipo de subscripción
                            </div>
                            <div className="auris-mySubscription-body-subscriptionType">
                                <span>{getType()} <TypeIcon></TypeIcon></span>
                            </div>
                        </div>

                        <div className='auris-mySubscription-body-item'>
                            <div className="auris-mySubscription-body-title">
                                Audios permitidos
                            </div>
                            <div className="auris-mySubscription-body-counter">
                                <span>{audioCont} de {tier.maxCounterAudios}</span>
                            </div>
                            <div className="auris-mySubscription-body-title">
                                hasta el
                            </div>
                            <div className="auris-mySubscription-body-counter">
                                { tier.id === 4 ? 'infinito y más allá' : <span>{("0" + endUserPeriod.getDate()).slice(-2)}-{("0" + (endUserPeriod.getMonth() + 1)).slice(-2)}-{endUserPeriod.getFullYear()}</span> }
                            </div>
                        </div>

                    </Grid>
                </div>
                {
                    subscription.expirationDate ?
                        <div className="auris-mySubscription-footer-expiration">
                            <div>
                                <span>Tu subscripción ha sido</span> <span style={{ color: "#e5a1d7", fontWeight: "bold" }}>cancelada</span>
                            </div>
                            <div>
                                <span>Podrás seguir usando la aplicación hasta el día: </span><span style={{ color: "#e5a1d7", fontWeight: "bold" }}>{new Date(subscription.expirationDate).toLocaleString('es-ES', { dateStyle: 'long' })}</span>
                            </div>
                        </div>
                        :
                        <div className="auris-mySubscription-footer">
                            <div className="auris-mySubscription-footer-closeSubscription">
                                <Button className="redButtonTrial clearfix" onClick={() => onCloseSubscription()}>Quiero cancelar mi suscripción</Button>
                            </div>
                            {
                                localStorage.getItem('tier') > 0 &&
                                <div className="auris-mySubscription-footer-closeSubscription">
                                    <Button className="redButtonTrial clearfix" onClick={navigateToBilling}>Histórico de facturas</Button>
                                </div>
                            }
                        </div>
                }
            </Grid>
        </div>
    );

}