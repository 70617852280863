import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Landing } from "../pages/landing/index";
import { Login } from "../pages/login/index"
import { Register } from "../pages/register/index"
import { Dashboard } from "../pages/dashboard/index"
import { Audios } from "../pages/audios/index";
import { Voices } from "../pages/voices/index";
import { AudioPlayer } from "../pages/audio-player/index";
import { Snippet } from "../pages/snippet/index"
import { Stats } from "../pages/stats/index"
import { Profile } from "../pages/profile/index";
import { AudioEditor } from "../pages/audio-editor";
import { AudioPlayerEditor } from "../pages/audio-player-editor";
import { Contact } from "../pages/contact";
import { Privacy } from "../pages/privacy";
import { Terms } from "../pages/terms";
import { Tiers } from "../pages/tiers";
import { Checkout } from "../pages/checkout";
import { Catalogue } from "../pages/catalogue";
import { ResetPassword } from "../pages/resetPassword";
import { NewPassword } from "../pages/newPassword";
import { Sounds } from "../pages/sounds";
import { Subscriptions } from "../pages/subscriptions";

function RoutesComponent(){

    return (
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Landing/>}/>
            <Route path="login" element={<Login/>}/>
            <Route path="register" element={<Register/>}/>
            <Route path="contact" element={<Contact/>}/>
            <Route path="privacy" element={<Privacy/>}/>
            <Route path="terms" element={<Terms/>}/>
            <Route path="tiers" element={<Tiers/>}/>
            <Route path="checkout" element={<Checkout/>}/>
            <Route path="voices" element={<Catalogue/>}/>
            <Route path="resetPassword" element={<ResetPassword/>}/>
            <Route path="newPassword" element={<NewPassword/>}/>
            <Route path="dashboard" element={<Dashboard/>}>
              <Route path="audios" element={<Audios/>}/>
              <Route path="voices" element={<Voices/>}/>
              <Route path="sounds" element={<Sounds/>}/>
              <Route path="audios/editor" element={<AudioEditor/>}/>
              <Route path="audios/editor/:id" element={<AudioEditor/>}/>
              <Route path="audios/editor/:name" element={<AudioEditor/>}/>
              <Route path="audioplayer" element={<AudioPlayer/>}/>
              <Route path="audioplayer/editor" element={<AudioPlayerEditor/>}/>
              <Route path="audioplayer/editor/:id" element={<AudioPlayerEditor/>}/>
              <Route path="snippet" element={<Snippet/>}/>
              <Route path="stats" element={<Stats/>}/>
              <Route path="profile" element={<Profile/>}/>
              <Route path="subscriptions" element={<Subscriptions/>}/>
            </Route>
          </Routes>
        </BrowserRouter>
    );
  }
  
  export default RoutesComponent;