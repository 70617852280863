import AurisApi from "../utils/auris-api";

const aurisApi = new AurisApi(process.env.REACT_APP_AURIS_API_URL);

//Users
export const login = (email, password) => {
    return aurisApi.postUnsigned(`/auth/login`, { email, password })
}

export const createUser = (user) => {
    return aurisApi.postUnsigned(`/user`, { user });
}

export const updateUser = (user) => {
    return aurisApi.put(`/user/${user.id}`, { user });
}

export const completeUserRegistration = (email, tier, paymentMethod) => {
    return aurisApi.post(`/user/completeRegistration`, { email, tier, paymentMethod });
}

export const closeUserSubscription = (email) => {
    return aurisApi.post(`/user/closeSubscription`, { email });
}

export const isUserSubscriptionIsActive = () => {
    return aurisApi.get(`/user/isSubscriptionActive`);
}

export const updateUserSubscriptionPrice = (email, priceCode) => {
    return aurisApi.post(`/user/updateUserSubscriptionPrice`, {email, priceCode});
}

export const findOneUser = () => {
    return aurisApi.get(`/user`);
}

export const getUserTier = () => {
    return aurisApi.get(`/user/getTier`);
}

export const getUserSubscription = () => {
    return aurisApi.get(`/user/getSubscription`);
}

export const getAudioCounter = () => {
    return aurisApi.get(`/user/getAudioCounter`);
}

export const sendContactEmail = async (email, name, body) => {
    const response = await aurisApi.postUnsigned('/user/contact', { email, name, body });
    return response;
}

export const sendEnterpriseEmail = async (email, name, body) => {
    const response = await aurisApi.postUnsigned('/user/enterpriseSubscriptionMail', { email });
    return response;
}

export const register = (firstName, lastName, email, password, tier) => {
    return aurisApi.postUnsigned(`/auth/register`, { firstName, lastName, email, password, tier });
}

export const resetPassword = (email) => {
    return aurisApi.postUnsigned(`/auth/resetPassword`, { email });
}

export const setPassword = (password, passwordConfirm, token) => {
    return aurisApi.postUnsigned(`/auth/newPassword`, { password, passwordConfirm, token });
}

export const refreshToken = () => {
    return aurisApi.post(`/auth/refreshToken`);
}

//Tiers
export const getTiers = () => {
    return aurisApi.getUnsigned(`/tier`);
}

//Voices
export const getVoices = () => {
    return aurisApi.getUnsigned(`/audio/voices`);
}

export const getVoicesByUser = () => {
    return aurisApi.post(`/audio/voices`);
}

export const getAllVoices = () => {
    return aurisApi.getUnsigned(`/audio/allVoices`);
}

export const getVoiceByAlias = (alias) => {
    return aurisApi.getUnsigned(`/audio/voices/${alias}`);
}

//Sounds
export const getSounds = () => {
    return aurisApi.getUnsigned(`/audio/sounds`);
}


export const getSoundByName = (templateName) => {
    return aurisApi.getUnsigned(`/audio/sounds/${templateName}`);
}

//Audios

//Este método devuelve el fichero de audio generado en base a un texto y una voz
export const createSpeech = (text, voice,) => {
    return aurisApi.postUnsigned(`/audio`, { text, voice });
}

export const previewAudio = (text, voice, soundTemplateId, sections) => {
    return aurisApi.post(`/audio/preview`, { text, voice, soundTemplateId, sections });
}

export const createAudioDB = (text, voice) => {
    return aurisApi.post(`/audio/speech`, { text, voice });
}

export const generateAudioUser = (text, voice, title, sound, sections) => {
    return aurisApi.post(`/audio/speech`, { text: text, voice: voice, title: title, soundTemplateId: sound, sections });
}

export const getAudiosByUser = () => {
    return aurisApi.get(`/audio`);
}

export const getAudioByScriptId = (scriptId) => {
    return aurisApi.get(`/audio/speech/${scriptId}`);
}

export const updateAudio = (text, voice, title) => {
    return aurisApi.put(`/audio`, { text, voice, title });
}

export const deleteAudio = (audioId) => {
    return aurisApi.delete(`/audio/${audioId}`);
}

export const getAudioById = (audioId) => {
    return aurisApi.get(`/audio/${audioId}`);
}


//AudioPlayers

export const createAudioplayerDB = (player) => {
    return aurisApi.post(`/player`, { player: player });
}

export const getPlayersByUser = () => {
    return aurisApi.get(`/player/`);
}

export const getPlayerById = (playerId) => {
    return aurisApi.get(`/player/${playerId}`);
}

export const updatePlayer = (player) => {
    return aurisApi.put(`/player/${player.id}`, { player });
}

export const deletePlayer = (playerId) => {
    return aurisApi.delete(`/player/${playerId}`);
}

// Checkout

export const createCheckoutSession = (priceId) => {
    return aurisApi.postUnsigned(`/user/createCheckoutSession`, { priceId },{'Content-Type': 'application/json'});
}

/*
export const createPortalSession = (sessionId) => {
    return aurisApi.post(`/user/createPortalSession`, { sessionId },{'Content-Type': 'application/json'});
}
*/
export const createPortalSession = (mail) => {
    return aurisApi.post(`/user/createPortalSession`, { mail },{'Content-Type': 'application/json'});
}

export const createPortalSessionFromCheckoutSession = (sessionId, email) => {
    return aurisApi.post(`/user/createPortalSessionFromCheckoutSession`, { sessionId:sessionId, email:email }, {'Content-Type': 'application/json'});
}

// Logs
export const getAudiosLogs = (params) => {
    return aurisApi.get(`/log?idAudios=${params['audios']}&startDate=${params['dates']['startDate']}&endDate=${params['dates']['endDate']}`);
}

//Summerize
export const Summerize = (text) => {
    return aurisApi.post(`/openai`, { text });
}