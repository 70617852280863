import './App.css';
import RoutesComponent from "./router";
import { useEffect } from 'react';
import { findOneUser } from './adapters/api';

function App() {
  useEffect(() => {
    async function fetchData() {
      const email = localStorage.getItem('email');
      if (email) {
        const {data} = await findOneUser(email);
        if (data?.user) {
          localStorage.setItem('username', data.user.firstName + " " + data.user.lastName);
          localStorage.setItem('tier', data.user.tier)
          localStorage.setItem('remainingWords', data.user.remainingWords)
        }
      }
    }
    fetchData();
  }, [])

  return (
    <div className="App">
      <RoutesComponent></RoutesComponent>
    </div>
  );
}

export default App;
