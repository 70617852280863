import './DialogTextArea.css';
import SpeakerTextArea from '../../components/speakerTextArea/SpeakerTextArea';
import React from 'react';


export default function DialogTextArea({ dialogs = [], textRef, onDialogChange, onDialogClose, selectedUserPause, selectedUserProsody, selectedUserSub, selectedPauseIcon, selectedProsodyIcon}) {
    return (
        <div className="auris-dialog-panel">
            {dialogs.map((dialog) => (<>
                <div><SpeakerTextArea
                    key={`key_${dialog.id}`}
                    id={dialog.id}
                    voice={dialog.voice}
                    colorStyle={dialog.colorStyle}
                    order={dialog.order}
                    text={dialog.text}
                    onChange={onDialogChange}
                    onClose={onDialogClose}
                    selectedPause={selectedUserPause}
                    selectedProsody={selectedUserProsody}
                    renderProsody={selectedProsodyIcon}
                    selectedSub={selectedUserSub}
                    renderPause={selectedPauseIcon}
                >
                </SpeakerTextArea></div>
            </>))}
        </div>
    );
}