import {
    Grid,
    Button,
    FormControlLabel,
    Switch,
    Box,
    Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate, useSearchParams, useOutletContext } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MySubscription from '../../components/mySubscription/MySubscription';
import libg from '../../assets/img/check.png';
import {
    createCheckoutSession,
    getTiers,
    getUserSubscription,
    updateUserSubscriptionPrice,
    closeUserSubscription,
    sendEnterpriseEmail
} from '../../adapters/api';
import LoadingSpinner from '../../components/loadingSpinner/LoadingSpinner';

const Swal = require('sweetalert2');

export const Subscriptions = () => {

    const [tiers, setTiers] = useState([]);
    const [tier, setTier] = useState([]);
    const [mySubscription, setMysubscription] = useState();
    const [paymentMethod, setPaymentMethod] = useState("mensual");
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const context = useOutletContext();

    React.useEffect(async () => {
        const responseTiers = await getTiers();
        setTiers(responseTiers.data.tiers);
        const miSubscription = await getUserSubscription();
        setMysubscription(miSubscription.data);
        const tier = responseTiers.data.tiers.find((i) => { return i.id === miSubscription.data.tierId });
        setTier(tier);
    }, []);

    const getTierCost = (id) => {
        const tier = tiers.find((i) => { return i.id === id });
        let price = {};
        let result = 0;
        switch (paymentMethod) {
            case 'mensual':
                price = tier.price.find((i) => { return i.type.includes("mensual") });
                result = price.amount;
                break;
            case 'anual':
                price = tier.price.find((i) => { return i.type.includes("anual") });
                result = price.amount;
                break;
            default: result = 0;
        }
        return result;
    }

    const onPaymentMethodChange = (event) => {
        const value = event.target.checked ? "anual" : "mensual";
        setPaymentMethod(value);
    }

    const onChangeClick = async (tierId) => {
        try {
            setLoading(true);
            const newPriceCode = getTierCode(tierId);
            const newMaxCounterAudios = getTierMaxCounterAudios(tierId);
            const oldPrice = tier.price.find((i) => { return i.type === paymentMethod });
            if (oldPrice.code) {
                const userMail = localStorage.getItem("Email");
                await updateUserSubscriptionPrice(userMail, newPriceCode);
                localStorage.setItem("tier", tierId);
                context.setAudioMaximum(newMaxCounterAudios);
                setLoading(false);
                Swal.fire({
                    title: 'Éxito',
                    text: 'Tu subscripción ha sido actualizada correctamente.',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
                navigate('/dashboard/audios');
            }
            else {
                const response = await createCheckoutSession(newPriceCode);
                localStorage.setItem("tier", tierId);
                setLoading(false);
                window.location.href = response.data.url;
            }
        }
        catch (exception) {
            setLoading(false);
            Swal.fire({
                title: 'Error',
                text: 'Hubo un error procesando tu subscripción',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
    }

    const onEnterpriseClick = async () => {
        try {
            setLoading(true);
            const mail = localStorage.getItem("email");
            await sendEnterpriseEmail(mail);
            setLoading(false);
            Swal.fire({
                title: 'Éxito',
                text: 'Hemos enviado un correo a nuestros comerciales. En breve se pondrán en contacto contigo.',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            navigate('/dashboard/audios');
        }
        catch (exception) {
            setLoading(false);
            Swal.fire({
                title: 'Error',
                text: 'Hubo un error enviando el correo',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
    }

    const onCloseSubscription = async () => {
        try {
            setLoading(true);
            const response = await closeUserSubscription(localStorage.getItem('email'));
            setMysubscription(response.data);
            setLoading(false);
        }
        catch (exception) {
            setLoading(false);
            Swal.fire({
                title: 'Error',
                text: 'Hubo un error al cancelar tu subscripción',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
    }

    const getTierCode = (tierId) => {
        const resultTier = tiers.find((i) => { return i.id === tierId });
        const price = resultTier.price.find((i) => { return i.type === paymentMethod });
        return price.code;
    }

    const getTierMaxCounterAudios = (tierId) => {
        const resultTier = tiers.find((i) => { return i.id === tierId });
        return resultTier.maxCounterAudios;
    }

    const showTier = (tierId) => {
        if (tierId > mySubscription.tierId) return true;
        return false;
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: '#fb4859',
                light: '#fb4859',
                dark: '#fb4859',
                contrastText: '#fb4859'
            }
        },
        typography: {
            fontFamily: "gibson"
        }
    });

    return (
        tiers.length > 0 && mySubscription ?
            <>
                <Box sx={{ width: '100%' }}>
                    <div>

                        <MySubscription
                            subscription={mySubscription}
                            tier={tier}
                            audioCont={context.audioCont}
                            endPeriod={context.userEndPeriod}
                            onCloseSubscription={onCloseSubscription}
                        >
                        </MySubscription>

                        <Grid container>
                            <Grid item xs={12} >
                                <div className="titleHome full-width center">
                                    <Typography style={{ color: 'gray' }} className="menuTitle" variant="h4" component="h4">
                                        ¿Quieres evolucionar tu suscripción?
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>

                        <div className='wrapper'>
                            <div style={{ textAlign: "center" }}>
                                <Grid style={{ display: "grid", justifyContent: "center" }}>
                                    <div style={{ textAlign: "center", width: "333px", marginBottom: "20px" }}>
                                        <ThemeProvider theme={theme}>
                                            <FormControlLabel
                                                style={{ color: "grey" }}
                                                control={<Switch onChange={onPaymentMethodChange} />}
                                                label={`Método de pago: ${paymentMethod.charAt(0).toUpperCase() + paymentMethod.slice(1)}`}
                                            />
                                        </ThemeProvider>
                                    </div>
                                </Grid>
                                <Grid container spacing={3}>
                                    {
                                        showTier(0) &&

                                        <Grid item xs={12} md={4}>
                                            <div className='price-card'>
                                                <p className='price-card-title'>
                                                    PLAN GRATUITO
                                                </p>
                                                <span className='price-card-subtitle'>
                                                    Voy a usar voces regularmente, pero no en grandes cantidades
                                                </span>
                                                <p className='price-card-price'>
                                                    {getTierCost(0)} €
                                                </p>
                                                <p className='price-card-list-title'>
                                                    Funcionalidades
                                                </p>
                                                <ul className='price-card-list'>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Crea 1 audio al mes
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Combinación voces
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Mezcla de voz y sonido
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Sin descarga de audio
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Customizar player
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Voces Premium
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Sonidos
                                                    </li>
                                                    <li style={{ color: 'transparent' }}>
                                                        .
                                                    </li>
                                                    <li style={{ color: 'transparent' }}>
                                                        .
                                                    </li>
                                                </ul>
                                                {/*<Button className="redButtonTrial clearfix" href="https://auris.wispform.com/0e04dee1">PRUEBA AURIS</Button>*/}
                                                <Button className="redButtonTrial clearfix" onClick={() => onChangeClick(0)}>Actualizar</Button>
                                            </div>
                                        </Grid>
                                    }
                                    {
                                        showTier(1) &&

                                        <Grid item xs={12} md={4}>
                                            <div className='price-card'>
                                                <p className='price-card-title'>
                                                    CANTO EN LA DUCHA
                                                </p>
                                                <span className='price-card-subtitle'>
                                                    Voy a usar voces regularmente, pero no en grandes cantidades
                                                </span>
                                                <p className='price-card-price'>
                                                    {getTierCost(1)} €
                                                </p>
                                                <p className='price-card-list-title'>
                                                    Funcionalidades
                                                </p>
                                                <ul className='price-card-list'>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Crea 8 audios al mes
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Combinación voces
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Mezcla de voz y sonido
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Sin descargar de audio
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Customizar player
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Voces Premium
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Sonidos
                                                    </li>
                                                    <li style={{ color: 'transparent' }}>
                                                        .
                                                    </li>
                                                    <li style={{ color: 'transparent' }}>
                                                        .
                                                    </li>
                                                </ul>
                                                <Button className="redButtonTrial clearfix" onClick={() => onChangeClick(1)}>Actualizar</Button>
                                            </div>
                                        </Grid>
                                    }
                                    {
                                        showTier(2) &&

                                        <Grid item xs={12} md={4}>
                                            <div className='price-card'>
                                                <p className='price-card-title'>
                                                    SOY CANTANTE PRO
                                                </p>
                                                <span className='price-card-subtitle'>
                                                    Voy a tranformar en voz muchos textos en mi trabajo
                                                </span>
                                                <p className='price-card-price'>
                                                    {getTierCost(2)} €
                                                </p>
                                                <p className='price-card-list-title'>
                                                    Funcionalidades
                                                </p>
                                                <ul className='price-card-list'>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Crea 20 audios al mes
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Combinación voces
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Mezcla de voz y sonido
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Descargar audio
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Customizar player
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Voces Premium
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Sonidos
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Estadísticas
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Voces inglés
                                                    </li>
                                                </ul>

                                                <Button className="redButtonTrial clearfix" onClick={() => onChangeClick(2)}>Actualizar</Button>
                                            </div>
                                        </Grid>
                                    }
                                    {
                                        showTier(3) &&

                                        <Grid item xs={12} md={4}>
                                            <div className='price-card'>
                                                <p className='price-card-title'>
                                                    SOY SOPRANO
                                                </p>
                                                <span className='price-card-subtitle'>
                                                    Me dedico a publicar y gestionar grandes cantidades de contenidos que quiero transformar a voz
                                                </span>
                                                <p className='price-card-price'>
                                                    {getTierCost(3)} €
                                                </p>
                                                <p className='price-card-list-title'>
                                                    Funcionalidades
                                                </p>
                                                <ul className='price-card-list'>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Crea 60 audios al mes
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Combinación voces
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Mezcla de voz y sonido
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Descargar audio
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Customizar player
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Voces Premium
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Sonidos
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Estadísticas
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Voces inglés
                                                    </li>
                                                    <li style={{ color: 'transparent' }}>
                                                        .
                                                    </li>
                                                    <li style={{ color: 'transparent' }}>
                                                        .
                                                    </li>
                                                    <li style={{ color: 'transparent' }}>
                                                        .
                                                    </li>
                                                </ul>

                                                <Button className="redButtonTrial clearfix" onClick={() => onChangeClick(3)}>Actualizar</Button>
                                            </div>
                                        </Grid>
                                    }
                                    {
                                        showTier(4) &&

                                        <Grid item xs={12} md={4}>
                                            <div className='price-card'>
                                                <p className='price-card-title'>
                                                    SOY EMPRESA
                                                </p>
                                                <span className='price-card-subtitle'>
                                                    Soy empresa, tengo dudas sobre casos de uso, pero quiero un proyecto ad hoc
                                                </span>
                                                <p className='price-card-price-text'>
                                                    Consúltanos
                                                </p>
                                                <p className='price-card-list-title'>
                                                    Funcionalidades
                                                </p>
                                                <ul className='price-card-list'>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Audios ilimitados
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Combinación voces
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Mezcla de voz y sonido
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Descargar audio
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Customizar player
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Voces Premium
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Sonidos
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Estadísticas
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Soporte dediado
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Mantemimiento
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Integración a medida
                                                    </li>
                                                    <li style={{ backgroundImage: `url(${libg})` }}>
                                                        Voces inglés
                                                    </li>
                                                </ul>
                                                <Button className="redButtonTrial clearfix" onClick={onEnterpriseClick}>Actualizar</Button>
                                            </div>
                                        </Grid>
                                    }
                                </Grid>
                            </div>
                        </div>
                        <LoadingSpinner isLoading={loading} />
                    </div>
                </Box>
            </>
            :
            <div></div>
    )
}
