import React from 'react';
import "./SSMLButton.css"
import { Tooltip } from '@mui/material';

export default function SSMLButton({ handleClick, name, value, icon, type, tooltipTitle }) {

    const prosodyButtonStyle={
        color: "white",
        backgroundColor: "rgb(116 103 121)",
        borderRadius: "25px",
        borderColor: "transparent",
        font: "italic bold 12px arial,serif",
        margin: "5px 1px 2px 3px",
        cursor: "pointer",
    }

    const pauseButtonStyle={
        color: "white",
        backgroundColor: "rgb(198 155 154)",
        borderRadius: "25px",
        borderColor: "transparent",
        font: "italic bold 12px arial,serif",
        margin: "5px 1px 2px 3px",
        cursor: "pointer",
    }

    const subButtonStyle={
        color: "white",
        backgroundColor: "#FB4859",
        borderRadius: "25px",
        borderColor: "transparent",
        font: "italic bold 12px arial,serif",
        margin: "5px 1px 2px 3px",
        cursor: "pointer",
    }

    const checkStyles=(type)=>{
        if(type.toString().toLowerCase()==="pause".toString().toLowerCase()){
            return pauseButtonStyle
        }else if(type.toString().toLowerCase()==="prosody".toString().toLowerCase()){
            return prosodyButtonStyle
        }else if(type.toString().toLowerCase()==="sub".toString().toLowerCase()){
            return subButtonStyle
        }
    }

    return (
        <Tooltip title={tooltipTitle}>
            <button style={checkStyles(type)} className='ssmlButton' onClick={(e) => handleClick(type, icon, e)} name={name} value={value}>{name}</button>
        </Tooltip>
    )
}