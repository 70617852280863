import {
  Box,
  Card,
  Grid,
  Link,
  Button,
  CardMedia,
  Container,
  Typography,
  CardContent,
} from '@mui/material';
import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Header } from '../../components/header/index';
import { Footer } from '../../components/footer/index';
import { Home } from '../home/index';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Arrow from '../../assets/img/arrow.png'
import Pink from '../../assets/img/pink_icon.png'
import Orange from '../../assets/img/orange_icon.png'
import Red from '../../assets/img/red_icon.png'
import Step1 from '../../assets/img/step1.png'
import Step2 from '../../assets/img/step2.png'
import Step3 from '../../assets/img/step3.png'
import Step4 from '../../assets/img/step4.png'
import Cost from '../../assets/img/cost.svg'
import demo from '../../assets/demo.mp4'
import posterDemo from '../../assets/img/posterDemo.png'

const theme = createTheme({});

export const Landing = () => {
  const isUserLoged = () => {
    return localStorage.getItem("email") ?  true : false;
  }
  return (
    <HelmetProvider>
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Auris Audio - Convertimos lectores en oyentes</title>
        <meta name="description" content="Facilita a tu audiencia el consumir tu contenido como quieran. Pasa tu texto a audio en minutos mediante voces AI." />
      </Helmet>
      <CssBaseline />
      <Header />
      <main>
        {/* Hero unit */}
        <Box
          xs={{
            bgcolor: '#FBFAFA',
            pt: 8,
            pb: 6,
          }}
        >
          <Container className="textAreaLandingSection" xs={12} sm={12}>
            <Home />
          </Container>
        </Box>
        <Box className="landing-info">
          <div className='wrapper'>
            <img className="img-info" src={Arrow} width={32} height={43} alt="arrow" />
            <Typography className="title-info">
              ¿Sabes qué es lo más valioso que tenemos los humanos?
            </Typography>
            <Typography className="info">
              El tiempo. Fácil, pero tan difícil de conseguirlo.
            </Typography>
            <Typography className="info">
              Andamos todo el día atropellados, de aquí para allá,
              y ¿sabes para qué? Para conseguir dinero que nos haga comprar tiempo cuando nos jubilemos.
              Yo no sé tú, pero esperar a la jubilación no parece ser la mejor de las ideas.
              Con Auris puedes comprar tiempo desde ya. ¿Cómo? Pasando tu contenido escrito a audio para que pueda ser consumido
              mientras haces otras cosas. Así de simple.
            </Typography>
          </div>
        </Box>
        <Container maxWidth="xl" className="landing-section">
          <div className='wrapper'>
            <Typography className="landing-title">Si se puede leer se puede escuchar</Typography>
            <Grid container spacing={3} >
              <Grid item xs={12} sm={4} >
                <Card className="landing-card">
                  <CardMedia
                    component="img"
                    image={Pink}
                    alt="pink"
                    sx={{
                      width: '85px',
                      height: '85px',
                      margin: 'auto',
                      marginTop: '51px'
                    }}
                  />
                  <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                    <Typography className="title-card" gutterBottom variant="h5" component="h2">
                      Genera voces AI
                    </Typography>
                    <Typography className="info-card">
                      Genera voces realistas para podcast o videos
                      mediante nuestro generador de voces AI
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card className="landing-card">
                  <CardMedia
                    component="img"
                    image={Red}
                    alt="red"
                    sx={{
                      width: '85px',
                      height: '85px',
                      margin: 'auto',
                      marginTop: '51px'
                    }}
                  />
                  <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                    <Typography className="title-card" gutterBottom variant="h5" component="h2">
                      Artículos escuchados
                    </Typography>
                    <Typography className="info-card">
                      Convierte tus artículos escritos en audios que podrán
                      disfrutar mientras hacen otras cosas
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card className="landing-card">
                  <CardMedia
                    component="img"
                    image={Orange}
                    alt="orange"
                    sx={{
                      width: '85px',
                      height: '85px',
                      margin: 'auto',
                      marginTop: '51px'
                    }}
                  />
                  <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                    <Typography className="title-card" gutterBottom variant="h5" component="h2">
                      Que tu web/app hable
                    </Typography>
                    <Typography className="info-card">
                      Transforma todo el contenido de tu web o app en audio de manera simple
                      y en solo unos minutos
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} className="demo-title">
                <Typography className="video-title">Te explicamos cómo funciona</Typography>
                <Typography className="step-info">Descubre todo lo que Auris puede ofrecerte en el siguiente vídeo</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={7} className="video-wrapper">
                <video className="video-responsive" controls poster={posterDemo}>
                  <source src={demo} type="video/mp4" />
                </video>
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <Typography className="step">PASO 1</Typography>
                <Typography className="step-title">Carga tu texto en la herramienta</Typography>
                <Typography className="step-info">El primer paso es cargar el texto que deseas convertir a audio en el panel de nuestra herramienta.
                  También puedes añadir sonidos de fondo para aumentar la atención del oyente
                </Typography>
              </Grid >
              <Grid item xs={12} sm={12} md={5}>
                <img src={Step1} alt="img-steps" className='img-responsive' />
              </Grid>

              <Grid item xs={12} sm={12} md={7} className='mobile'>
                <Typography className="step">PASO 2</Typography>
                <Typography className="step-title">Elige la voz que más se adapte a ti</Typography>
                <Typography className="step-info">Cada voz transmite cosas distintas, así que, ofrecemos un amplio catálogo de voces AI para que puedas
                  seleccionar la que mejor se adapte a tu contenido.
                  Podrás incluso seleccionar varias para un mismo texto y conseguir un efecto diálogo
                </Typography>
              </Grid >
              <Grid item xs={12} sm={12} md={5} className="left-image mobile">
                <img src={Step2} alt="img-steps" className='img-responsive' />
              </Grid>

              <Grid item xs={12} sm={12} md={7} className="left-image desktop">
                <img src={Step2} alt="img-steps" className='pt-dk-4' />
              </Grid>
              <Grid item xs={12} sm={12} md={5} className='desktop'>
                <Typography className="step pt-dk-4">PASO 2</Typography>
                <Typography className="step-title">Elige la voz que más se adapte a ti</Typography>
                <Typography className="step-info">Cada voz transmite cosas distintas, así que, ofrecemos un amplio catálogo de voces AI para que puedas
                  seleccionar la que mejor se adapte a tu contenido.
                  Podrás incluso seleccionar varias para un mismo texto y conseguir un efecto diálogo
                </Typography>
              </Grid >

              <Grid item xs={12} sm={12} md={7}>
                <Typography className="step">PASO 3</Typography>
                <Typography className="step-title">Personaliza la apariencia del player</Typography>
                <Typography className="step-info">Aunque (casi) todo nos entre por los oídos,
                  también cuidamos lo visual. Podrás personalizar
                  la apariencia del player a tu gusto
                </Typography>
              </Grid >
              <Grid item xs={12} sm={12} md={5}>
                <img src={Step3} alt="img-steps" className='img-responsive' />
              </Grid>

              <Grid item xs={12} sm={7} md={7} className='mobile'>
                <Typography className="step">PASO 4</Typography>
                <Typography className="step-title">Embebe el player en tu web</Typography>
                <Typography className="step-info">Obten de manera fácil y rápida tu JS Snippet y embebe el player en el apartado que prefieras de tu web.
                  Aunque, si lo prefieres, ¡también podrás descargar el archivo
                  y llevarlo contigo a cualquier plataforma!
                </Typography>
              </Grid >
              <Grid item xs={12} sm={12} md={5} className="left-image mobile">
                <img src={Step4} alt="img-steps" className='img-responsive' />
              </Grid>

              <Grid item xs={12} sm={12} md={7} className="left-image desktop">
                <img src={Step4} alt="img-steps" className="pt-dk-4" />
              </Grid>
              <Grid item xs={12} sm={12} md={5} className='desktop'>
                <Typography className="step pt-dk-4">PASO 4</Typography>
                <Typography className="step-title">Embebe el player en tu web</Typography>
                <Typography className="step-info">Obten de manera fácil y rápida tu JS Snippet y embebe el player en el apartado que prefieras de tu web.
                  Aunque, si lo prefieres, ¡también podrás descargar el archivo
                  y llevarlo contigo a cualquier plataforma!
                </Typography>
              </Grid >
            </Grid>
          </div>
          <div className='wrapper'>
            <Box className="box-contact">
              <Grid container>
                <Grid item xs={12} md={3}>
                  <Typography className="contact-title">Proyectos</Typography>
                  <Typography className="contact-title" sx={{ color: '#FB4859' }}>Ad hoc</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="contact-info" >
                    ¿Quieres que sean locutores profesionales los que lean tus artículos? ¿Quieres una voz sintética única para ti? ¿O que automaticemos la transformación de tus contenidos de texto a audio mediante Inteligencia Artificial?
                  </Typography>
                  <Typography className="contact-info" >
                    Tenemos muchas formas de personalizar tus proyectos.
                  </Typography>

                </Grid>
                <Grid xs={12} md={3} item>
                  <Button href="/contact" className="redButtonOutlineContact">Contáctanos →</Button>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Container>
        <Box className="cost-box">
          <div className='wrapper'>
            <Grid container>
              <Grid item xs={12} sm={5}>
                <img width={230} height={180} src={Cost} alt="cost" />
              </Grid>
              <Grid item xs={12} sm={7}>
                <Typography className="cost-info">
                  Disponemos de varios rangos de precios, en función de las necesidades que tengas.
                </Typography>
                <Typography className="cost-info">
                  Si es caro o barato solo puedes determinarlo tú.
                </Typography>
                <Typography className="cost-info">
                  Lo que sí que podemos decirte, que con Auris ahorarrarás tiempo a tus usuarios. Y no nos engañemos, el tiempo es lo más valioso que tenemos.
                </Typography>
                <Link href="/tiers" color="inherit" underline="none">
                  <Typography className="cost-info" sx={{ color: '#FBFAFA', marginTop: 2 }}>Consultar planes de precios →</Typography>
                </Link>
              </Grid>
            </Grid>
          </div>
        </Box>
        {
        !isUserLoged() &&
        <Container className='prueba-box'>
          <div className='wrapper'>
            <Box className="test-box">
              <Grid columns={1}>
                <Typography className="title-box">Prueba Auris gratis</Typography>
                <Typography className="info-box">Comienza a usarlo gratis. Saborea las ventajas
                  de pasar tu texto a audio en minutos.
                </Typography>


              </Grid>
              <Button className="redButtonTrial no-center" href="/register">PRUEBA AURIS</Button>
              <Typography className="doubts-text">¿Tienes dudas? <Link color="inherit" target="_blank" underline="none" href="/contact">Contáctanos</Link></Typography>
            </Box>
          </div>
        </Container>
      }
      </main>
      <Footer />
    </ThemeProvider>
    </HelmetProvider>
  );
}
