import React from 'react';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Header } from '../../components/header/index';

const theme = createTheme();
export const Terms = () => {
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Header></Header>
                <div className='wrapper'>
                    <div className="titleHome">
                        Aviso Legal
                    </div>
                </div>
            </Container>
        </ThemeProvider>
    )
}