import './AudioCounter.css';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {LinearProgress, ThemeProvider} from '@mui/material';
import {Typography} from '@mui/material';
import {Box} from '@mui/material';
import { createTheme } from '@mui/material/styles';

export default function AudioCounter({cont,total,showTitle,endPeriod}){

  const endUserPeriod = new Date(endPeriod);

  useEffect(async () => {
    
  },[]);
  
    function LinearProgressWithLabel(props) {
      const val = cont === 0 ? 0 : (cont * 100) / total;
        setProgress(val);
        return (
          <Box display="flex" alignItems="center">
            <Box minWidth={15} style={{marginRight: "5px"}}>
              <Typography variant="body2" color="textSecondary">{cont}</Typography>
            </Box>
            <Box width="100%" mr={1}>
              <ThemeProvider theme={theme}>
              <LinearProgress color="primary" variant="determinate" {...props} />
              </ThemeProvider>
            </Box>
            <Box minWidth={15}>
              <Typography variant="body2" color="textSecondary">{total}</Typography>
            </Box>
          </Box>
        );
    }

    LinearProgressWithLabel.propTypes = {
        /**
         * The value of the progress indicator for the determinate and buffer variants.
         * Value between 0 and 100.
         */
        value: PropTypes.number.isRequired,
    };

    const theme = createTheme({
      palette: {
        primary: {
          main: '#fb4859',
          light: '#fb4859',
          dark: '#fb4859',
          contrastText: '#fb4859'
        }
      },
    });

    const [progress, setProgress] = React.useState(0);


    return(
        <div style={{ width:"100%" }}>
            <div className="auris-audioCounter-title">{showTitle ? "Audios creados" : ""}</div>
            <LinearProgressWithLabel value={progress} />
            {
              endPeriod &&
              <div className="auris-audioCounter-period">Fin de periodo: {("0" + endUserPeriod.getDate()).slice(-2)}-{("0" + (endUserPeriod.getMonth() + 1)).slice(-2)}-{endUserPeriod.getFullYear()}</div>
            }
        </div>
    );
    
}