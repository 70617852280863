import React from 'react';

export const AudioPlayer = (props) => {
    return (
        <div id="audio-player-container">
            <audio src="" id="audio" preload="auto"></audio>
            <label id="audio-title">{props.title}</label>
            <button id="play-pause-btn" type="button"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path id="p-p-btn" fill={props.color} d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c7.6-4.2 16.8-4.1 24.3 .5l144 88c7.1 4.4 11.5 12.1 11.5 20.5s-4.4 16.1-11.5 20.5l-144 88c-7.4 4.5-16.7 4.7-24.3 .5s-12.3-12.2-12.3-20.9V168c0-8.7 4.7-16.7 12.3-20.9z" /></svg></button>
            <input type="range" id="seek-slider" max="100" value="0"></input>
            <span id="duration" class="time">0:00</span>
            <div>
                <input id="speedController" type="button" value='x1' class="speed" /> 
            </div>
            <div class="watermarkDiv">
                <span id="watermark" class="watermark">Convierte lectores en oyentes con <a href='https://auris.space/' target={"_blank"} rel="noreferrer">Auris</a></span>
            </div>

        </div>
    )
}
