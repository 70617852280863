import { 
    Grid,
    Container,
    CssBaseline,
  } from '@mui/material';
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Header } from '../../components/header/index';
import { Footer } from '../../components/footer/index';

const theme = createTheme();

export const Privacy = () => {
    return (
        <HelmetProvider>
        <ThemeProvider theme={theme}>
            <Helmet>
                <title>Auris Audio - Privacidad</title>
                <meta name="description" content="Bienvenido a la página legal que podría cambiarte la vida. O no. Si tienes pensado leértela entera espero que te pongas cómodo. Te va a hacer falta." />
            </Helmet>
            <Container component="main" maxWidth="xs" style={{ marginBottom: '10em' }}>
                <CssBaseline />
                <Header />
                <div className='wrapper px-dk-20'>
                    <Grid container className='pt-dk-10'>
                        <Grid item xs={12} >
                            <div className="titleHome full-width">
                                <span>Política de privacidad </span>
                            </div>
                        </Grid>
                        <Grid item xs={12} >
                            <div className='privacy'>
                                <div className="title-privacy">
                                    <span>Información adicional sobre protección de datos</span>
                                </div>
                                <p>
                                    Bienvenido a la página legal que podría cambiarte la vida. O no. Si tienes pensado leértela entera espero que te pongas cómodo. 
                                    Te va a hacer falta. Lo que se hace y no se hace con tus datos personales en esta web lo encontrarás aquí. Esta política expresa 
                                    cómo se tratará y protegerá la información personal de todas las personas que se relacionan con V OIKERS a través de este sitio web. 
                                    Por favor, debes leer todos los apartados del Aviso Legal, de la política de cookies y de la presente política de privacidad antes de 
                                    utilizar esta web.
                                </p>
                                <p>
                                    De conformidad con lo dispuesto en Reglamento UE 2016/679, del Parlamento Europeo y del Consejo del 27 de abril 
                                    de 2016 (RGPD) y la Ley Orgánica 3/2018, del 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales, 
                                    Voikers, S.L., te informa de que, mediante la aceptación de esta Política de Privacidad, prestas tu consentimiento expreso, informado, 
                                    libre e inequívoco para que los Datos que proporcionas, y sobre los que se aplican las medidas de seguridad, técnicas y organizativas 
                                    previstas en la normativa vigente, sean tratados por Voikers, S.L. como responsable del tratamiento.
                                </p>
                            </div>
                            <div className='privacy card'>
                                <div className="title-privacy">
                                    <span>¿Quién es el responsable de esta Web?</span>
                                </div>
                                <ul>
                                    <li>
                                        <strong>Identidad del responsable: </strong> Voikers, S.L.
                                    </li>
                                    <li>
                                        <strong>Nombre comercial: </strong> Auris
                                    </li>
                                    <li>
                                        <strong>NIF/CIF: </strong> 98798798798
                                    </li>
                                    <li>
                                        <strong>Dirección: </strong> Dirección
                                    </li>
                                    <li>
                                        <strong>Actividad: </strong> Marketing Online
                                    </li>
                                    <li>
                                        <strong>Datos de contacto del responsable o representante y del delegado de protección de datos en su caso: </strong> contacto@auris.com
                                    </li>
                                </ul>
                            </div>
                            <div className='privacy'>
                                <div className="title-privacy">
                                    <span>¿Qué datos personales se recogen en esta web?</span>
                                </div>
                                <p>
                                    Para las finalidades establecidas en esta Política de Privacidad, Nudista Investor recaba y trata los Datos Personales que se detallan 
                                    a continuación, que dependerán de los diferentes productos o servicios que solicite en esta Web:
                                </p>
                                <ul>
                                    <li>
                                        <strong>Datos identificativos: </strong> nombre, apellidos.
                                    </li>
                                    <li>
                                        <strong>Datos de contacto: </strong> dirección postal, correo electrónico.
                                    </li>
                                    <li>
                                        <strong>Datos contractuales: </strong> 
                                        datos de operaciones contractuales, DNI, productos y servicios adquiridos, operaciones financieras, datos de pago.
                                    </li>
                                    <li>
                                        <strong>Datos navegación: </strong>
                                        dirección IP, tipo e identificación del dispositivo, tipo de navegador, dominio a través del cual accede al Sitio Web, 
                                        datos de navegación, actividad en el Sitio Web.
                                    </li>
                                </ul>
                            </div>
                            <div className='privacy'>
                                <div className="title-privacy">
                                    <span>¿Con qué base legal se tratan esos datos?</span>
                                </div>
                                <p>
                                    Tratamos tus datos personales con las siguientes bases legales:
                                </p>
                                <ul>
                                    <li>
                                        <strong>La ejecución de un contrato </strong>
                                        con Auris para la contratación de servicios, publicar anuncios y gestionar los servicios solicitados.
                                    </li>
                                    <li>
                                        <strong>El consentimiento del usuario </strong> 
                                        en relación al contacto, la suscripción a contenidos y el envío de comunicaciones comerciales, vía mail, cookies o 
                                        sistemas de mensajería.
                                    </li>
                                    <li>
                                        <strong>El interés legítimo del responsable </strong> 
                                        del tratamiento para proteger a los usuarios de la web de Nudista Investor de abusos y fraudes en el uso de nuestros servicios.
                                    </li>
                                </ul>
                            </div>
                            <div className='privacy'>
                                <div className="title-privacy">
                                    <span>¿Con qué finalidades trataremos tus datos?</span>
                                </div>
                                <p>
                                    En esta web, existe diferentes formularios, en cada uno de ellos, la información que recoja se utilizará de la siguiente manera:
                                </p>
                                <ul>
                                    <li>
                                        <strong>Formularios de suscripción a contenidos: </strong>
                                        existen varios formularios para activar la suscripción. Los datos facilitados serán utilizados exclusivamente para enviar la 
                                        newsletter y mantenerte actualizado sobre novedades y ofertas puntuales, exclusivas para suscriptores de <a href="/"> auris.com</a>.
                                    </li>
                                    <li>
                                        <strong>Formulario de contacto: </strong> 
                                        también existe un formulario de contacto para consultas, sugerencias o contacto profesional. En este caso se utilizará la 
                                        dirección de correo electrónico para responder a las mismas y enviar la información que el usuario requiera a través de la web.
                                    </li>
                                    <li>
                                        <strong>Formulario de registro: </strong> 
                                        en este caso, solicitamos los siguientes datos personales: nombre, email, para que puedas crear una cuenta en nuestro sistema y 
                                        dar acceso a sus funcionalidades.
                                    </li>
                                    <li>
                                        <strong>Formulario de venta: </strong> 
                                        también trato datos para gestionar la compra del servicio de suscripción a la membresía <a href="/"> auris.com</a> incluyendo 
                                        la gestión del pedido, el pago y todas las operaciones relacionadas con la contratación del servicio o producto elegido. 
                                        Esto incluye enviar correos electrónicos de seguimiento, respuestas, facturas, recibos, etc.
                                    </li>
                                </ul>
                            </div>
                            <div className='privacy'>
                                <div className="title-privacy">
                                    <span>Tiempo de conservación de los datos personales</span>
                                </div>
                                <p>
                                    <strong>Datos de los Clientes: </strong> el periodo de conservación de los datos personales variará en función del servicio que el 
                                    Cliente contrate. En cualquier caso, será el mínimo necesario, pudiendo mantenerse hasta:
                                </p>
                                <ul>
                                    <li>
                                        <strong>4 años: </strong>
                                        Ley sobre Infracciones y Sanciones en el Orden Social (obligaciones en materia de afiliación, altas, bajas, cotización, pago de 
                                        salarios…); Arts. 66 y ss. Ley General Tributaria (libros de contabilidad…).
                                    </li>
                                    <li>
                                        <strong>5 años: </strong>
                                        Art. 1964 del Código Civil (acciones personales sin plazo especial).
                                    </li>
                                    <li>
                                        <strong>6 años: </strong> 
                                        Art. 30 del Código de Comercio (libros de contabilidad, facturas…).
                                    </li>
                                    <li>
                                        <strong>10 años: </strong>
                                        Art. 25 de la Ley de Prevención del Blanqueo de Capitales y Financiación del Terrorismo.
                                    </li>
                                </ul>
                                <p>
                                    <strong>Datos de los suscriptores: </strong> Desde que el usuario se suscribe hasta que se da de baja.
                                </p>
                                <p>
                                    <strong>Datos de potenciales clientes: </strong>
                                    los datos se conservarán en todo caso durante la vigencia de la relación comercial establecida y, una vez concluida, dos años, a 
                                    menos que el usuario solicite antes su supresión.
                                </p>
                            </div>
                            <div className='privacy'>
                                <div className="title-privacy">
                                    <span>¿Cuáles son tus derechos en los que concierne al uso de tus datos?</span>
                                </div>
                                <p>
                                    Cualquier persona tiene derecho a obtener confirmación sobre si en Nudista Investor estamos tratando datos personales que le conciernen 
                                    o no. Las personas interesadas tienen derecho a:
                                </p>
                                <ul>
                                    <li>
                                        Solicitar el acceso a los datos personales relativos al interesado.
                                    </li>
                                    <li>
                                        Solicitar su rectificación o supresión.
                                    </li>
                                    <li>
                                        Solicitar su cancelación.
                                    </li>
                                    <li>
                                        Solicitar la limitación de su tratamiento.
                                    </li>
                                    <li>
                                        Oponerse al tratamiento.
                                    </li>
                                    <li>
                                        Solicitar la portabilidad de los datos.
                                    </li>
                                </ul>
                                <p>
                                    Si ha otorgado su consentimiento para alguna finalidad concreta, tiene derecho a retirar el consentimiento otorgado en cualquier 
                                    momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.
                                </p>
                                <p>
                                    En caso de que sienta vulnerados sus derechos en lo concerniente a la protección de sus datos personales, especialmente cuando no 
                                    haya obtenido satisfacción en el ejercicio de sus derechos, puede presentar una reclamación ante la Autoridad de Control en materia 
                                    de Protección de Datos competente a través de su sitio web: 
                                    <a href="https://www.aepd.es/es" target="_blank" rel="noreferrer"> www.aepd.es</a>
                                </p>
                                <p>
                                    Para ejercitar estos derechos, puede escribir a 
                                    <a 
                                        href="mailto:contacto@auris.com?Subject=Ejercitar derechos sobre el uso de mis datos" 
                                        target="_blank" 
                                        rel="noreferrer"> contacto@auris.com</a>
                                </p>
                                <p>
                                    Existe un formulario para el ejercicio de sus derechos, lo puede pedir por email o si lo prefiere, puede usar los elaborados por la 
                                    <a href> Agencia Española de Protección de Datos o terceros.</a>
                                </p>
                                <p>
                                    Estos formularios deben ir firmados electrónicamente o ser acompañados de fotocopia del DNI.
                                </p>
                                <p>
                                    Si le representa alguien, debe adjuntar copia de su DNI, o debe estar firmado con su firma electrónica.
                                </p>
                                <p>
                                    Los formularios pueden ser presentados presencialmente, enviados por carta o por mail a la dirección del responsable que aparece al 
                                    inicio de este texto.
                                </p>
                            </div>
                            <div className='privacy'>
                                <div className="title-privacy">
                                    <span>Acciones de remarketing: Google Ads</span>
                                </div>
                                <p>
                                    Ads es una plataforma de Google destinada a los ANUNCIANTES que quieren publicitarse en GOOGLE. En Nudista Investor se utiliza 
                                    Google Ads, la plataforma de publicidad de Google que permite crear campañas y anuncios para mostrarlos en otras páginas webs. 
                                    Al generar un anuncio, se puede segmentar el público por:
                                </p>
                                <ul>
                                    <li>
                                        Lugar
                                    </li>
                                    <li>
                                        Datos demográficos (edad, sexo, etc.)
                                    </li>
                                    <li>
                                        Intereses (actividades, aficiones, etc.) 
                                    </li>
                                    <li>
                                        Lo que compran en internet y por otros canales
                                    </li>
                                    <li>
                                        Etc.
                                    </li>
                                </ul>
                                <p>
                                    Los datos obtenidos a través de Google Ads están sujetos a esta política de privacidad desde el momento en que el usuario deja sus datos 
                                    en el formulario de esta web para unirse al boletín de suscripciones. En ningún caso se utilizará la información procedente de Google con 
                                    una finalidad diferente.
                                </p>
                                <p>
                                    En cualquier momento como usuario, puedes revocar tu consentimiento a través de 
                                    <a href="https://www.facebook.com/settings?tab=ads" target="_blank" rel="noreferrer"> https://www.facebook.com/settings?tab=ads</a>, 
                                    donde puedes configurar la recepción de anuncios. Asimismo, puedes indicar que no quieres anuncios basado en intereses de Facebook, 
                                    ni de otras empresas, a través de la Alianza Europea de Publicidad Digital Interactiva (European Interactive Digital Advertising 
                                    Alliance, EDAA) de Your On Line Choices 
                                    (<a href="https://www.youronlinechoices.com/es" target="_blank" rel="noreferrer"> https://www.youronlinechoices.com/es</a>) 
                                    o en la configuración de tu ordenador o tu dispositivo móvil.
                                </p>
                                <p>
                                    Para usar la función de públicos personalizados, esta web utiliza la herramienta Website Custom Audience de Facebook, que hace uso
                                    del pixel de Facebook que marca al visitante de esta página web de forma anonimizada. Si deseas oponerte al uso de Website Custom 
                                    Audiences de Facebook, tienes la posibilidad de hacerlo en: 
                                    <a  
                                        href="https://www.facebook.com/ads/website_custom_audiences"
                                        target="_blank"
                                        rel="noreferrer"> https://www.facebook.com/ads/website_custom_audiences
                                    </a>
                                    .
                                </p>
                                <p>
                                    Para una mayor información sobre el alcance y finalidad de la recogida de los datos por parte de Facebook y su posterior tratamiento, 
                                    así como, para configurar tu privacidad debes acudir a los siguientes enlaces:
                                </p>
                                <p>
                                    <a 
                                        href="https://www.facebook.com/privacy/explanation" 
                                        target="_blank" 
                                        rel="noreferrer"> https://www.facebook.com/privacy/explanation
                                    </a>
                                    .
                                </p>
                                <p>
                                    Pixel de seguimiento de conversiones de Facebook
                                </p>
                                <p>
                                    Para la optimización de las campañas publicitarias de los anuncios que se publican en Facebook, y con finalidades estadísticas, 
                                    se usa el pixel de seguimiento de conversiones de Facebook Inc., 1601 s. California Ave, Palo Alto, CA 94304, que rastrea las 
                                    acciones de los usuarios después de hacer clic en un anuncio.
                                </p>
                                <p>
                                    Los datos que se obtienen a través de dicho pixel son anónimos y no hacen referencia a personas físicas identificadas, 
                                    ni se almacenan, ni se crean perfiles con sus datos personales.
                                </p>
                                <p>
                                    Estos datos pueden ser almacenados y tratados por Facebook, y este puede vincular estos datos con tu cuenta de Facebook y también 
                                    utilizarlos para fines publicitarios propios de acuerdo con la política de uso de datos de Facebook que encontrarás en 
                                    <a  
                                        href="https://www.facebook.com/about/privacy"
                                        target="_blank"
                                        rel="noreferrer"> https://www.facebook.com/about/privacy
                                    </a>
                                    , recientemente actualizada en 
                                    <a  
                                        href="https://www.facebook.com/about/privacy/update"
                                        target="_blank"
                                        rel="noreferrer"> https://www.facebook.com/about/privacy/update
                                    </a>
                                    .
                                </p>
                                <p>
                                    Puedes posibilitar a Facebook y a sus socios la inserción de anuncios publicitarios dentro y fuera de Facebook, y para estos 
                                    fines puede almacenarse una cookie en tu ordenador.
                                </p>
                            </div>
                            <div className='privacy'>
                                <div className="title-privacy">
                                    <span>Notificación y declaración de brechas</span>
                                </div>
                                <p>
                                    Notificación de seguridad y declaración de brechas
                                </p>
                                <p>
                                    Voikers, S.L. asume medidas de seguridad adecuadas al nivel de riesgo para proteger la información personal contra pérdida, uso 
                                    indebido y acceso no autorizado, divulgación, alteración y destrucción, teniendo en cuenta los riesgos que conlleva el procesamiento 
                                    y la naturaleza de la información personal; no obstante, si Voikers, S.L., determina que sus datos de servicios han sido malversados 
                                    (incluso por un empleado de Voikers, S.L.) se han visto expuestos por una brecha de seguridad o adquiridos de forma incorrecta por un 
                                    tercero, Voikers, S.L. te informará de inmediato de dicha brecha de seguridad, apropiación o adquisición indebida.
                                </p>
                            </div>
                            <div className='privacy'>
                                <div className="title-privacy">
                                    <span>¿A qué destinatarios se comunicarán tus datos?</span>
                                </div>
                                <p>
                                    Muchas herramientas utilizadas en esta web para gestionar datos son contratadas por terceros. Para prestar servicios estrictamente 
                                    necesarios para el desarrollo de la actividad, Voikers, S.L. comparte datos con los siguientes prestadores bajo sus correspondientes 
                                    condiciones de privacidad:
                                </p>
                                <ul>
                                    <li>
                                        <strong>ActiveCampaign, Inc.</strong>
                                        , con domicilio en EEUU. ActiveCampaign, Inc. trata los datos con la finalidad de realizar sus servicios de email marketing a 
                                        Nudista investor según su política de privacidad. Los boletines electrónicos o Newsletter están gestionados por ActiveCampaing. 
                                        Al utilizar los servicios de esta plataforma para la realización de campañas de marketing por correo electrónico, gestión de 
                                        suscripciones y envío de boletines, debes saber que ActiveCampaing tienen sus servidores alojados  fuera de la UE, en EEUU.
                                    </li>
                                    <li>
                                        <strong>Servicio de análisis: </strong> 
                                        Google Analytics: un servicio analítico de web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está 
                                        en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (“Google”). Google Analytics utiliza “cookies”, 
                                        que son archivos de texto ubicados en tu ordenador, para ayudar a nudistainvestor.com analizar el uso que hacen los usuarios del 
                                        sitio web. La información que genera la cookie acerca de su uso de nudistainvestor.com (incluyendo tu dirección IP) será 
                                        directamente transmitida y archivada por Google en los servidores de Estados Unidos.
                                    </li>
                                    <li>
                                        <strong>Stripe, Inc.</strong> 
                                        Utilizo Stripe para gestionar los pagos en mi web. Stripe ofrece servicios que permiten a las plataformas y comerciantes operar 
                                        negocios y realizar transacciones de pago en línea de manera segura. El fundamento jurídico para la transmisión de los datos es 
                                        el art. 6, párrafo 1, letra a) del RGPD (consentimiento) y el art. 6, párrafo 1, letra b) del RGPD (procesamiento para el 
                                        cumplimiento de un contrato) Stripe cumple con las leyes aplicables para proporcionar un nivel adecuado de protección de datos 
                                        para la transferencia de sus Datos personales a los EE. UU. Stripe Inc. está certificada bajo el Marco de Protección de Privacidad 
                                        UE-EE. UU. Y Suiza-EE. UU. Para obtener más información, consulte la Política de protección de privacidad de Stripe.
                                    </li>
                                    <li>
                                        <strong>Factura Directa.</strong> 
                                        Utilizo Factura Directa para gestionar las facturas, la contabilidad y la fiscalidad de mi web. Puedes consultar su Política de 
                                        Privacidad aquí.Todos ellos son proveedores de servicios externos (por ejemplo, servicios de procesamiento de pago, procesamiento 
                                        de pedidos, análisis, gestión campañas de marketing, gestión de sitios web, y distribución de correo electrónico y otros proveedores
                                        de servicios similares), a fin de que estos puedan realizar funciones comerciales en nombre de Auris.
                                        Pueden tener acceso a esta web de forma ocasional la empresa de desarrollo y mantenimiento web, o la de hosting. Las mismas tienen 
                                        firmado un contrato de prestación de servicios que les obliga a mantener el mismo nivel de privacidad que se aplica a los 
                                        tratamientos realizados en esta web. Estas empresas son:
                                    </li>
                                    <li>
                                        <strong>Hosting: </strong> 
                                        Nicalia.com , más información en 
                                        <a href="https://www.nicalia.com" target="_blank" rel="noreferrer"> https://www.nicalia.com</a>
                                    </li>
                                    <li>
                                        <strong>Plataforma web: </strong> 
                                        WordPress.org más información en <a href="https://wordpress.org/" target="_blank" rel="noreferrer"> https://wordpress.org </a> 
                                        trata los datos con la finalidad de realizar sus servicios de soporte a <a href="/"> auris.com</a>. Cualquier transferencia 
                                        internacional de datos derivada de la utilización de herramientas o proveedores de servicios, estará adherida al convenio Privacy 
                                        Shield, que garantiza que las empresas de software americano cumplen las políticas de protección de datos europeas en materia de 
                                        privacidad, secreto y seguridad de los datos.
                                    </li>
                                </ul>
                            </div>
                            <div className='privacy'>
                                <div className="title-privacy">
                                    <span>Enlaces de afiliados y publicidad</span>
                                </div>
                                <p>
                                    En esta web se ofrece al Usuario contenidos patrocinados, anuncios y/o enlaces de afiliados.
                                </p>
                                <p>
                                    Entre los enlaces que te puedes encontrar, existe la posibilidad de informar y de hacer referencia a productos y/o servicios y/o 
                                    infoproductos de terceros. La información que aparece en estos enlaces de afiliados o los anuncios insertados es facilitada por los 
                                    propios anunciantes, por lo que nudistainvestor.com no se hace responsable en ningún caso de inexactitudes o errores que pudieran 
                                    contener los anuncios, ni puede ofrecer garantías sobre la experiencia, integridad o responsabilidad de los anunciantes, o la calidad 
                                    de sus productos y/o servicios, aunque siempre se recomendarán aquellos productos o servicios que nudistainvestor.com y su equipo 
                                    hayan probado.
                                </p>
                                <p>
                                    Además, los anuncios permanecerán publicados en el portal hasta que sean eliminados o suspendidos por parte del anunciante o de 
                                    <a href="https://nudistainvestor.com" target="_blank" rel="noreferrer"> nudistainvestor.com</a> que lógicamente no es responsable 
                                    de que, una vez dados de baja en sus bases de datos, los anuncios continúen siendo indexados en buscadores ajenos a este portal.
                                </p>
                                <p>
                                    Todo usuario debe comprender que cualquier relación contractual o extracontractual entre el Usuario y los anunciantes, afiliados o 
                                    terceras personas contactadas a través de esta web, se entienden realizados única y exclusivamente entre el usuario y el anunciante 
                                    y/o tercera persona. <a href="https://nudistainvestor.com" target="_blank" rel="noreferrer"> nudistainvestor.com</a> únicamente actúa 
                                    como cauce o medio publicitario y, por tanto, no tiene ningún tipo de responsabilidad sobre los daños o perjuicios de cualquier 
                                    naturaleza ocasionados con motivo de sus negociaciones, conversaciones y/o relaciones contractuales o extracontractuales con los 
                                    anunciantes o terceras personas físicas o jurídicas contactadas a través de esta web.
                                </p>
                            </div>
                            <div className='privacy'>
                                <div className="title-privacy">
                                    <span>Secreto y seguridad de los datos</span>
                                </div>
                                <p>
                                    <a href="/"> auris.com</a> se compromete al uso y tratamiento de los datos personales del usuario, respetando su confidencialidad, de 
                                    acuerdo con la finalidad de aquellos; así como a dar cumplimiento a su obligación de guardarlos y adaptar todas las medidas para evitar 
                                    la alteración, pérdida, tratamiento o acceso no autorizado, de conformidad con lo establecido en la normativa vigente de protección de 
                                    datos.
                                </p>
                                <p>
                                    (Esta web incluye un certificado SSL. Se trata de un protocolo de seguridad que hace que tus datos viajen de manera íntegra y segura; 
                                    es decir, la transmisión de los datos entre un servidor y el usuario web, y en retroalimentación es totalmente cifrada o encriptada.)
                                </p>
                                <p>
                                    <a href="/"> auris.com</a> no puede garantizar la absoluta inexpugnabilidad de la red Internet, ni, por tanto, la violación de los datos
                                    mediante accesos fraudulentos a ellos por parte de terceros.
                                </p>
                                <p>
                                    Con respecto a la confidencialidad del procesamiento, Voikers, S.L. se asegurará de que cualquier persona que esté autorizada por 
                                    Voikers, S.L. para procesar los datos del cliente (incluido su personal, colaboradores y prestadores), estará bajo la obligación 
                                    apropiada de confidencialidad (ya sea un deber contractual o legal).
                                </p>
                                <p>
                                    Cuando se presente algún incidente de seguridad, al darse cuenta Voikers, S.L. deberá notificar al Cliente sin demoras indebidas y 
                                    deberá proporcionar información oportuna relacionada con el Incidente de Seguridad tal como se conozca, o cuando el cliente lo solicite 
                                    razonablemente.
                                </p>
                            </div>
                            <div className='privacy'>
                                <div className="title-privacy">
                                    <span>Exactitud y veracidad de los datos</span>
                                </div>
                                <p>
                                    Como usuario, eres el único responsable de la veracidad y corrección de los datos que remitas a nudistainvestor.com exonerando a 
                                    Voikers, S.L. (Auris), de cualquier responsabilidad al respecto. Los usuarios garantizan y responden, en cualquier caso, de la 
                                    exactitud, vigencia y autenticidad de los datos personales facilitados, y se comprometen a mantenerlos debidamente actualizados. 
                                    El usuario acepta proporcionar información completa y correcta en el formulario de contacto o suscripción.
                                </p>
                            </div>
                            <div className='privacy'>
                                <div className="title-privacy">
                                    <span>Revocabilidad</span>
                                </div>
                                <p>
                                    El consentimiento prestado, tanto para el tratamiento como para la cesión de los datos de los interesados, es revocable en cualquier 
                                    momento comunicándolo a Voikers, S.L. (Auris) en los términos establecidos en esta Política para el ejercicio de los derechos ARCO. 
                                    Esta revocación en ningún caso tendrá carácter retroactivo.
                                </p>
                            </div>
                            <div className='privacy'>
                                <div className="title-privacy">
                                    <span>Cambios en la política de privacidad</span>
                                </div>
                                <p>
                                    Voikers, S.L. (Auris) se reserva el derecho a modificar la presente política para adaptarla a novedades legislativas o 
                                    jurisprudenciales, así como a prácticas de la industria. En dichos supuestos, anunciará en esta página los cambios introducidos con 
                                    razonable antelación a su puesta en práctica.
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>
            <Footer />
        </ThemeProvider>
        </HelmetProvider>
    )
}
