import axios from 'axios';

export default class AurisApi {
    url = "https://auris.space/api";

    constructor(url) {
        this.url = url;
    }

    // Methods with Authorization header
    get(endpoint, axiosConfig) {
        return axios.get(`${this.url}${endpoint}`, this._getConfigWithAuthorizationHeader(axiosConfig))
    }
    post(endpoint, data, axiosConfig) {
        return axios.post(`${this.url}${endpoint}`, data, this._getConfigWithAuthorizationHeader(axiosConfig));
    }
    put(endpoint, data, axiosConfig) {
        return axios.put(`${this.url}${endpoint}`, data, this._getConfigWithAuthorizationHeader(axiosConfig));
    }
    delete(endpoint, axiosConfig) {
        return axios.delete(`${this.url}${endpoint}`, this._getConfigWithAuthorizationHeader(axiosConfig));
    }

    // Methods without authorization header
    getUnsigned(endpoint, axiosConfig) {
        return axios.get(`${this.url}${endpoint}`, axiosConfig)
    }
    postUnsigned(endpoint, data, axiosConfig) {
        return axios.post(`${this.url}${endpoint}`, data, axiosConfig);
    }
    putUnsigned(endpoint, data, axiosConfig) {
        return axios.put(`${this.url}${endpoint}`, data, axiosConfig);
    }
    deleteUnsigned(endpoint, axiosConfig) {
        return axios.delete(`${this.url}${endpoint}`, axiosConfig);
    }

    _getConfigWithAuthorizationHeader(axiosConfig) {
        if (axiosConfig) {
            return {
                ...axiosConfig,
                headers: axiosConfig.headers
                    ? {...axiosConfig.headers, ...this._getAuthorizationHeader() }
                    : {...this._getAuthorizationHeader()}
            }
        } else {
            return {headers: {
                ...this._getAuthorizationHeader()
            }}
        }
    }

    _getAuthorizationHeader() {
       return { "authorization": localStorage.getItem('auth-token') };
    }
}