import './SoundStyleChip.css'

export default function SoundStyleChip({ tag }) {
    return (
        <span className="auris-sound-style-chip" style={{ backgroundColor: getBackgroundColorForTag(tag), color: getTextColorForTag(tag) }}>{tag}</span>
    )
}

function getBackgroundColorForTag(tag) {
    const colorForSoundStyle = {
        'intenso': '#FB4859',
        'minimalista': '#FF908D',
        'reflexivo': '#E5A1D7',
        'melódico': '#26022F',
        'alegre': '#A1D7E6',
        'nostálgico': '#A1E6A5',
        'concentración': '#E6E5A1',
        'acústico': '#E6BCA1',
        'enérgico': '#ffd88c',
        'animado': '#fff48c',
        'activo': '#d7ff8c',
        'relajado': '#8fff8c',
        'divertido': '#8cffd2',
        'misterioso': '#8cf8ff',
        'ambiente': '#8ccfff',
        'positivo': '#958cff',
        'informativo': '#c68cff',
        'motivado': '#ef8cff',
        'trabajo': '#ff8ce9',
        'meditation': '#ff8cb9',
        'viajes': '#ff8c93',
        'default': '#b880ad'
        // #80aeb8
        // #81b880
    }
    return colorForSoundStyle[tag] || colorForSoundStyle['default'];
}

function getTextColorForTag(tag) {
    const backgroundColor = getBackgroundColorForTag(tag);
    switch (backgroundColor) {
        case '#26022F':
        case '#958cff':
        case '#FB4859':
            return '#eee';
        default:
            return '#333'
    }
}