import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Logo from '../../assets/img/logo.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { register } from '../../adapters/api';

const Swal = require('sweetalert2');

function Copyright(props) {
  return (
    <div>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © Auris '}

        {new Date().getFullYear()}
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center" >
        <Link color="inherit" target="_blank" href="https://voikers.com/">
          Voikers
        </Link>{' & '}
        <Link color="inherit" target="_blank" href="https://avantgardeit.es/">
          Avantgarde IT
        </Link>
      </Typography>
    </div>

  );
}

const theme = createTheme();



export const Register = () => {
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    try {
      //TODO: Cifrar password antes de mandar password
      const userData = {
        email: data.get('email'),
        firstName: data.get('firstName'),
        lastName: data.get('lastName'),
        password: data.get('password'),
        tier: -1
      };
      const res = await register(userData.firstName, userData.lastName, userData.email, userData.password, userData.tier);
      if (res.status === 200) {

        navigate('/login');

        Swal.fire({
          title: 'Éxito',
          text: 'Usuario creado satisfactoriamente. Ya puedes hacer login en la aplicación',
          icon: 'success',
          confirmButtonText: 'Ok'
        });


      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Error al intentar durante el registro: ' + error.response.data.message,
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  };


  return (
    <HelmetProvider>
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Auris Audio - Regístrate</title>
        <meta name="description" content="Crea una cuenta en Auris de manera gratuita y prueba todo lo que puede ofrecerte" />
      </Helmet>
      <div className='wrapper'>
        <div className='register'>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography component="h1" variant="h5" className="title">
                <Link href="/" variant="body2">
                  <img src={Logo} width={"138px"} height={"69px"} alt="logo" />
                </Link>
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label="Nombre"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      label="Apellido"
                      name="lastName"
                      autoComplete="family-name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Correo electrónico"
                      name="email"
                      autoComplete="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Contraseña"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox value="allowExtraEmails" color="primary" defaultChecked
                        sx={{
                          color: '#fb4859',
                          '&.Mui-checked': {
                            color: '#fb4859',
                          },
                        }} />}
                      label={<span>Acepto la <a href="/privacy">política de privacidad</a></span>}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ textAlign: 'center' }}>
                  <Button
                    type="submit"
                    fullWidth
                    className="red-button"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Regístrate
                  </Button>
                  </Box>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Link href="/login" variant="body2">
                        ¿Ya tienes una cuenta? Identificate
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
          </Container>
        </div>
        <Copyright sx={{ mt: 8, mb: 1 }} />
      </div>
    </ThemeProvider>
    </HelmetProvider>
  );
}


