import { 
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import React from 'react';

export const DateFilter = (props) => {
  return (
    <>
      <FormControl variant='filled'>
        <InputLabel id="date-filter-label">Fecha</InputLabel>
        <Select
          id="date-filter"
          value={props.value}
          onChange={props.onChange}
          style={{ width: 240 }}
        >
          <MenuItem key={'today'} value={'today'}>hoy</MenuItem>
          <MenuItem key={'yesterday'} value={'yesterday'}>ayer</MenuItem>
          <MenuItem key={'week'} value={'week'}>última semana</MenuItem>
          <MenuItem key={'month'} value={'month'}>último mes</MenuItem>
          <MenuItem key={'year'} value={'year'}>este año</MenuItem>
        </Select>
      </FormControl>
    </>
  )
}