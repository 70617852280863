import './SpeakerFrame.css';
import Star from '../../assets/img/star.svg';
import FlagImage from '../../components/flagImage/FlagImage';
import { translateAccents } from '../../utils/utils';
import React, { useState } from 'react';
import {IconButton, Tooltip, MenuItem} from '@mui/material';
import {Close as CloseIcon} from '@mui/icons-material';
import {ArrowDropDown as ArrowDropDownIcon} from '@mui/icons-material';
import {AddCircleOutlineOutlined as AddIcon} from '@mui/icons-material';


export default function SpeakerFrame({index,voice,voices,borderColor,backgroundColor,onSelectClick,onCloseClick,onChangeVoice}){

    const colorStylePanel = { 
        borderColor: borderColor, 
        backgroundColor: backgroundColor
    };
    const [showVoices, setShowVoices] = useState(false);

    const onSelectVoiceClick = () => {
        const show = showVoices ? false : true;
        setShowVoices(show);
    }
    
    return(
        <div className="auris-frame-speaker">
        <div className="auris-frame" style={colorStylePanel}>


            <span style={{ textTransform: 'capitalize' }}>
                    <img 
                    style={{ display: voice.type === 'premium' ? '' : 'none' }} 
                    src={Star} 
                    width={'15px'} 
                    height={'15px'} 
                    alt={voice.type} />
                    {voice.alias} - {voice.gender === 'female' ? 'Femenino' : 'Masculino'} - 
                    <FlagImage 
                        style={{ marginTop: '3px', marginLeft: '5px', position: 'absolute' }}  
                        flag={voice.accent} 
                        accent={voice.accent} 
                        width={'20px'} 
                        height={'20px'}
                    />
            </span>

            <div className="auris-frame-button-select">
                <IconButton size="small"
                aria-label="close"
                color="inherit"
                onClick={() => onSelectClick(voice,colorStylePanel)}
                >
                    <AddIcon fontSize="small" />
                </IconButton>
            </div>

            <div className="auris-frame-button-select">
                <IconButton size="small"
                aria-label="close"
                color="inherit"
                onClick={onSelectVoiceClick}
                >
                    <ArrowDropDownIcon fontSize="small" />
                </IconButton>
            </div>

            {showVoices &&
            <div className="auris-frame-voices" style={{borderColor:borderColor}}>
                {voices.map((newVoice) => (
                  <MenuItem
                    key={newVoice.alias}
                    value={newVoice}
                    onClick={()=>{setShowVoices(false);onChangeVoice(voice,newVoice)}}
                  >
                    <Tooltip title={translateAccents(newVoice.accent)} placement="right">
                      <span style={{ textTransform: 'capitalize' }}>
                        <img 
                          style={{ display: newVoice.type === 'premium' ? '' : 'none' }} 
                          src={Star} 
                          width={'15px'} 
                          height={'15px'} 
                          alt={newVoice.type} />
                        {newVoice.alias} - {newVoice.gender === 'female' ? 'Femenino' : 'Masculino'} - 
                        <FlagImage 
                          style={{ marginTop: '3px', marginLeft: '5px', position: 'absolute' }}  
                          flag={newVoice.accent} 
                          accent={newVoice.accent} 
                          width={'20px'} 
                          height={'20px'}
                        />
                      </span>
                    </Tooltip>
                  </MenuItem>
                ))}
            </div>
            }


        </div>  
        <div className="auris-franme-close">
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={()=> onCloseClick(voice.alias,voice.colorId)}
            >
            <CloseIcon fontSize="small" />
            </IconButton>
        </div>   
      </div>
    );
    
}