import { ReactComponent as VolumeLogo } from "../../assets/img/Volume-up.svg";
import { ReactComponent as IconPause } from "../../assets/img/icon-pause.svg";
import { ReactComponent as IconPlay } from "../../assets/img/play-circle.svg";
import { Howl } from "howler";
import { Tooltip, IconButton } from "@mui/material";
import { useState } from "react";
import "./AurisPlayPause.css";

export default function AurisPlayPause({
  soundUrl,
  getSoundUrl,
  loop = false,
  width = 25,
  height = 25,
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [sound, setSound] = useState(null);

  async function handleGenerate() {
    try {
      setIsGenerated(true);
      setIsLoading(true);
      if (sound) {
        handlePause();
      }
      const soundSrc = soundUrl || (await getSoundUrl());
      console.log(soundSrc);
      if (soundSrc) {
        setSound(
          new Howl({
            src: [soundSrc],
            format: ['wav'],
            html5: true,
            loop,
            autoplay: true,
            onplay: () => {
              setIsPlaying(true);
              setIsPaused(false);
              setIsLoading(false);
            },
            onstop: () => {
              setIsPlaying(false);
              setIsLoading(false);
              setIsPaused(false);
            },
            onpause: () => {
              setIsPaused(true);
              setIsPlaying(false);
              setIsLoading(false);
            },
            onend: () => {
              setIsPaused(false);
              setIsPlaying(false);
              setIsLoading(false);
            },
          })
        );
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsPaused(false);
      setIsPlaying(false);
      setIsLoading(false);
      setIsLoading(false);
    }
  }

  async function handlePlay() {
    if (isPaused && sound) {
      sound.play();
      return;
    }
  }

  function handlePause() {
    sound.pause();
  }

  let buttonContent;
  switch (true) {
    case isPlaying && isGenerated:
      buttonContent = (
        <>
          <IconButton onClick={() => handlePause()}>
            <IconPause
              className={`auris-play-pause__icon ${
                isPlaying ? "--is-playing" : ""
              } ${isPaused ? "--is-paused" : ""}`}
              style={{ color: "#26022F" }}
              width={width}
              height={height}
            />
          </IconButton>
          <IconButton onClick={() => handleGenerate()}>
            <VolumeLogo
              className={`auris-play-pause__icon ${
                isLoading ? "--is-loading" : ""
              }`}
              style={{ color: "#26022F" }}
              width={width}
              height={height}
            />
          </IconButton>
        </>
      );
      break;
    case !isPlaying && isGenerated:
      buttonContent = (
        <>
          <IconButton onClick={() => handlePlay()}>
            <IconPlay
              className={`auris-play-pause__icon ${
                isPlaying ? "--is-playing" : ""
              } ${isPaused ? "--is-paused" : ""}`}
              style={{ color: "#26022F" }}
              width={width}
              height={height}
            />
          </IconButton>
          <IconButton onClick={() => handleGenerate()}>
            <VolumeLogo
              className={`auris-play-pause__icon ${
                isLoading ? "--is-loading" : ""
              }`}
              style={{ color: "#26022F" }}
              width={width}
              height={height}
            />
          </IconButton>
        </>
      );
      break;
    default:
      buttonContent = (
        <>
          <IconButton onClick={() => handleGenerate()}>
            <VolumeLogo
              className={`auris-play-pause__icon ${
                isLoading ? "--is-loading" : ""
              }`}
              style={{ color: "#26022F" }}
              width={width}
              height={height}
            />
          </IconButton>
        </>
      );
  }

  return <Tooltip title="Escuchar">{buttonContent}</Tooltip>;
}
