import Planet from '../../assets/img/banderas/planet.svg';

const importAll = (r) => {
    let images = {};
    r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const flagImages = importAll(require.context('../../assets/img/banderas', false, /\.(png|jpe?g|svg)$/));

export default function FlagImage({accent, flag, width = '30px', height = '30px', style = {}}) {
    return (
        <img 
            src={flagImages[`${flag}.svg`] ? flagImages[`${flag}.svg`] : Planet}
            alt={accent || flag}
            width={width} height={height}
            style={style}
        />
    )
}
