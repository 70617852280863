import { 
  Card,
  Typography,
  CardContent,
} from '@mui/material';
import React from 'react';

export const Kpi = (props) => {
  return (
    <>
      <Card variant="outlined">
        <CardContent style={{ margin: '0 1em 0 1em' }}>
          <Typography inline align="left" variant="h6" color="text.secondary" gutterBottom>
            {props.title}
          </Typography>
          <Typography variant="h4" component="div">
            {props.value}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            {props.legend}
          </Typography>
        </CardContent>
      </Card>
    </>
  )
}