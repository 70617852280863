import {
    Box,
    Grid,
    Select,
    Button,
    Link,
    MenuItem,
    FormControl,
    Tooltip,
    InputLabel,
    Container,
    Typography,
    CssBaseline,
} from '@mui/material';
import { Howl } from 'howler';
import React, { useEffect, useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { getAllVoices } from '../../adapters/api';
import play from '../../assets/img/play.png';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { translateAccents } from '../../utils/utils'
import { Header } from '../../components/header/index';
import { Footer } from '../../components/footer/index';
import FlagImage from '../../components/flagImage/FlagImage'
import LoadingSpinner from '../../components/loadingSpinner/LoadingSpinner';
const theme = createTheme({});

function createData(id, name, gender, accent, audioSample, flag) {
    return { id, name, gender, accent, audioSample, flag };
}
let sound = new Howl({
    src: [
        "", // I have a link of an mp3 file stored in an S3 bucket
    ],
    loop: false
});
export const Catalogue = () => {
    const [voices, setVoices] = useState([]);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [accents, setAccents] = useState([]);
    const [rowsFiltered, setRowsFiltered] = useState([]);
    const playAudio = async (selectedVoice) => {
        sound.stop();
        try {
            setLoading(true);
            sound = new Howl({
                src: selectedVoice
            });

            sound.play();
            setLoading(false);
        } catch (error) { }
    }
    useEffect(() => {
        async function fetchData() {
            const voices = await getAllVoices();
            setVoices(voices.data.voices);
        }
        fetchData();
    }, [])
    useEffect(() => {
        const populateRows = () => {
            const arr = [];
            for (let index = 0; index < voices.length; index++) {
                arr.push(createData(voices[index]._id, voices[index].alias, voices[index].gender, translateAccents(voices[index].accent), voices[index].audioSample, voices[index].accent));
            }
            setRows(arr.sort((a, b) => (a.name < b.name ? -1 : 1)));
            setRowsFiltered(arr.sort((a, b) => (a.name < b.name ? -1 : 1)));
            setAccents(getUniqueListBy(arr, 'accent').sort((a, b) => a.accent.localeCompare(b.accent)));
        }
        populateRows();
    }, [voices])
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F0F0F0',
            borderColor: 'dedac9',
            color: theme.palette.common.black,
            fontWeight: 600
        },
        [`&.${tableCellClasses.body}`]: {
            borderRadius: '25px',
            fontSize: 14,
        },
    }));
    const getUniqueListBy = (arr, key) => {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }
    const handleChangeFilter = (event) => {
        if (!event.target.value) {
            setRowsFiltered(rows);
        } else {
            const arr = rows.filter((e) => e.accent === event.target.value)
            setRowsFiltered(arr);
        }
    }
    const isUserLoged = () => {
        return localStorage.getItem("email") ?  true : false;
    }
    return (
        <HelmetProvider>
        <ThemeProvider theme={theme}>
            <Helmet>
                <title>Auris Audio - Voces</title>
                <meta name="description" content="Prueba y elige nuestro listado de voces AI seleccionado el acento que mejor se adapte a tus necesidades" />
            </Helmet>
            <LoadingSpinner isLoading={loading} />
            <CssBaseline />
            <Header></Header>
            <main>
                <div className='wrapper'>
                    <Grid container className='pt-dk-10'>
                        <Grid item xs={12} >
                            <div className="titleHome full-width center">
                                <span>Prueba nuestras </span>
                                <span style={{ color: "#e5a1d7" }}>voces AI</span>
                            </div>
                            <div className="subtitleHome full-width center">Selecciona un acento para obtener una vista previa de las voces.
                            </div>
                        </Grid>
                    </Grid>
                    <div className='voice-list'>
                        <FormControl variant="standard" sx={{ mt: 10, minWidth: 200 }}>
                            <InputLabel className='filter-label'>Filtrar por acento</InputLabel>
                            <Select onChange={handleChangeFilter} className='filter' label='Filtrar por acento'>
                                <MenuItem value="">
                                    <em>Todos</em>
                                </MenuItem>
                                {accents.map((row) => (
                                    <MenuItem value={row.accent}>{row.accent}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <hr className='separator' />
                        <Grid container spacing={3}>
                            {rowsFiltered.map((row) => (
                                <Grid key={row.name} item xs={12} sm={6} md={4}>
                                    <div className='voice-tile'>
                                        <button onClick={() => playAudio(row.audioSample)} className='voice-play'>
                                            <img src={play} alt='' />
                                        </button>
                                        <div className='voice-title'>
                                            <span style={{ textTransform: 'capitalize' }}>{row.name}</span>
                                            &nbsp;
                                            <Tooltip title={row.accent} placement="right">
                                                <FlagImage flag={row.flag} accent={row.accent}></FlagImage>
                                            </Tooltip>
                                        </div>
                                        <div className='voice-subtitle'>
                                            <span>{row.gender === "male" ? "Masculino" : "Femenino"}</span>
                                        </div>
                                    </div>
                                </Grid>))}
                        </Grid>
                    </div>
                </div>
                <hr className='separator bg-black mt-10' />
                {
                !isUserLoged &&
                <Container className='prueba-box my-10'>
                    <div className='wrapper'>
                        <Box className="test-box">
                            <Grid columns={1}>
                                <Typography className="title-box">Prueba Auris gratis</Typography>
                                <Typography className="info-box">Registrate y comienza a usarlo gratis. Saborea las ventajas
                                    de pasar tu texto a audio en minutos.
                                </Typography>
                            </Grid>
                            <Button className="redButtonTrial no-center" href="/register">Quiero registrarme</Button>
                            <Typography className="doubts-text">¿Tienes dudas? <Link color="inherit" underline="none" target="_blank" href="/contact">Contáctanos</Link></Typography>
                        </Box>
                    </div>
                </Container>
                }
            </main>
            <Footer />
        </ThemeProvider>
        </HelmetProvider>
    );
}