import {
    Grid,
    Button,
    Container,
    FormControlLabel,
    Switch
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Header } from '../../components/header/index';
import { Footer } from '../../components/footer/index';
import libg from '../../assets/img/check.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    createCheckoutSession,
    createPortalSessionFromCheckoutSession,
    getTiers,
    completeUserRegistration,
    sendEnterpriseEmail
} from '../../adapters/api';

const Swal = require('sweetalert2');

export const Tiers = () => {

    const [tiers, setTiers] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState("mensual");
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(async () => {
        const isSuccess = searchParams.get("success") ? true : false;
        if (isSuccess) { await completeRegistration(); return; };
        const responseTiers = await getTiers();
        setTiers(responseTiers.data.tiers);
    }, []);

    const completeRegistration = async () => {
        const sessionId = searchParams.get("session_id");
        const userMail = localStorage.getItem("email");
        const response = await createPortalSessionFromCheckoutSession(sessionId, userMail);
        localStorage.setItem("tier", response.data.tier);
        Swal.fire({
            title: 'Éxito',
            text: 'Tu subscripción ha sido procesada correctamente.',
            icon: 'success',
            confirmButtonText: 'Ok'
        });
        navigate('/dashboard/audios');
    }

    const completeRegistrationNoStripe = async (tierId) => {
        const userMail = localStorage.getItem("email");
        const response = await completeUserRegistration(userMail, tierId, paymentMethod);
        localStorage.setItem("tier", tierId);
        Swal.fire({
            title: 'Éxito',
            text: 'Tu subscripción ha sido procesada correctamente.',
            icon: 'success',
            confirmButtonText: 'Ok'
        });
        navigate('/dashboard/audios');
    }

    const getTierCost = (id) => {
        const tier = tiers.find((i) => { return i.id === id });
        let price = {};
        let result = 0;
        switch (paymentMethod) {
            case 'mensual':
                price = tier.price.find((i) => { return i.type.includes("mensual") });
                result = price.amount;
                break;
            case 'anual':
                price = tier.price.find((i) => { return i.type.includes("anual") });
                result = price.amount;
                break;
            default: result = 0;
        }
        return result;
    }

    const onPaymentMethodChange = (event) => {
        const value = event.target.checked ? "anual" : "mensual";
        setPaymentMethod(value);
    }

    const onRegisterClick = async (tierId) => {
        const code = getTierCode(tierId);
        if (code) {
            const response = await createCheckoutSession(code);
            localStorage.setItem("tier", tierId);
            window.location.href = response.data.url;
        }
        else {
            localStorage.setItem("tier", tierId);
            await completeRegistrationNoStripe(tierId);
        }
    }

    const onEnterpriseClick = async () => {
        const mail = localStorage.getItem("email");
        await sendEnterpriseEmail(mail);
        Swal.fire({
            title: 'Éxito',
            text: 'Hemos enviado un correo a nuestros comerciales. En breve se pondrán en contacto contigo.',
            icon: 'success',
            confirmButtonText: 'Ok'
        });
    }

    const getTierCode = (tierId) => {
        const tier = tiers.find((i) => { return i.id === tierId });
        const price = tier.price.find((i) => { return i.type === paymentMethod });
        return price.code;
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: '#fb4859',
                light: '#fb4859',
                dark: '#fb4859',
                contrastText: '#fb4859'
            }
        },
        typography: {
            fontFamily: "Gibson"
        }
    });

    return (
        tiers.length > 0 &&
        <HelmetProvider>
        <ThemeProvider theme={theme}>
            <Helmet>
                <title>Auris Audio - Precios</title>
                <meta name="description" content="Te lo ponemos fácil. Elige el plan de pago que mejor se adapte a tus necesidades y sácale el mayor provecho a Auris." />
            </Helmet>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Header></Header>
                <Grid container>
                    <Grid item xs={12} >
                        <div className="titleHome full-width center">
                            <span>Planes de </span>
                            <span style={{ color: "#e5a1d7" }}>precios</span>
                        </div>
                        {
                            localStorage.getItem("email") &&
                            <div style={{ textAlign: "center" }}>
                                Ya has registrado tu cuenta. Para continuar, por favor selecciona un plan
                            </div>
                        }
                    </Grid>
                </Grid>
                <div className='wrapper'>
                    <div className='prices' style={{ textAlign: "center" }}>
                        <Grid container>
                            <Grid item xs={12} md={12} style={{ display: "grid", justifyContent: "center" }}>
                                <div style={{ textAlign: "center", width: "333px", marginBottom: "50px" }}>
                                    <FormControlLabel
                                        control={<Switch onChange={onPaymentMethodChange} />}
                                        label={`Método de pago: ${paymentMethod.charAt(0).toUpperCase() + paymentMethod.slice(1)}`}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <div className='price-card'>
                                    <p className='price-card-title'>
                                        PLAN GRATUITO
                                    </p>
                                    <span className='price-card-subtitle'>
                                        Solo quiero cotillear la aplicación y probarlo. Ya ampliaré mi plan más tarde...
                                    </span>
                                    <p className='price-card-price'>
                                        {getTierCost(0)} €
                                    </p>
                                    <p className='price-card-list-title'>
                                        Funcionalidades
                                    </p>
                                    <ul className='price-card-list'>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Crea 1 audio al mes
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Combinación voces
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Sin descarga de audio
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Customizar player
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Voces Premium
                                        </li>
                                        <li style={{ color: 'transparent' }}>
                                            .
                                        </li>
                                        <li style={{ color: 'transparent' }}>
                                            .
                                        </li>
                                    </ul>
                                    {/*<Button className="redButtonTrial clearfix" href="https://auris.wispform.com/0e04dee1">PRUEBA AURIS</Button>*/}
                                    {
                                        localStorage.getItem("email") &&
                                        <Button className="redButtonTrial clearfix" onClick={() => onRegisterClick(0)}>Elegir</Button>
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className='price-card'>
                                    <p className='price-card-title'>
                                        CANTO EN LA DUCHA
                                    </p>
                                    <span className='price-card-subtitle'>
                                        Voy a usar voces regularmente, pero no en grandes cantidades
                                    </span>
                                    <p className='price-card-price'>
                                        {getTierCost(1)} €
                                    </p>
                                    <p className='price-card-list-title'>
                                        Funcionalidades
                                    </p>
                                    <ul className='price-card-list'>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Crea 8 audios al mes
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Combinación voces
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Sin descargar de audio
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Customizar player
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Voces Premium
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Sonidos
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Estadísticas en tiempo real
                                        </li>
                                        <li style={{ color: 'transparent' }}>
                                            .
                                        </li>
                                        <li style={{ color: 'transparent' }}>
                                            .
                                        </li>
                                    </ul>
                                    {
                                        localStorage.getItem("email") &&
                                        <Button className="redButtonTrial clearfix" onClick={() => onRegisterClick(1)}>Elegir</Button>
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className='price-card'>
                                    <p className='price-card-title'>
                                        SOY CANTANTE PRO
                                    </p>
                                    <span className='price-card-subtitle'>
                                        Voy a tranformar en voz muchos textos en mi trabajo
                                    </span>
                                    <p className='price-card-price'>
                                        {getTierCost(2)} €
                                    </p>
                                    <p className='price-card-list-title'>
                                        Funcionalidades
                                    </p>
                                    <ul className='price-card-list'>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Crea 20 audios al mes
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Combinación voces
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Descargar audio
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Customizar player
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Voces Premium
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Sonidos
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Estadísticas en tiempo real
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Voces multi idioma
                                        </li>
                                    </ul>
                                    {
                                        localStorage.getItem("email") &&
                                        <Button className="redButtonTrial clearfix" onClick={() => onRegisterClick(2)}>Elegir</Button>
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className='price-card'>
                                    <p className='price-card-title-one-row'>
                                        SOY SOPRANO
                                    </p>
                                    <span className='price-card-subtitle'>
                                        Me dedico a publicar y gestionar grandes cantidades de contenidos que quiero transformar a voz
                                    </span>
                                    <p className='price-card-price'>
                                        {getTierCost(3)} €
                                    </p>
                                    <p className='price-card-list-title'>
                                        Funcionalidades
                                    </p>
                                    <ul className='price-card-list'>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Crea 60 audios al mes
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Combinación voces
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Descargar audio
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Customizar player
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Voces Premium
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Sonidos
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Estadísticas en tiempo real
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Voces multi idioma
                                        </li>
                                        <li style={{ color: 'transparent' }}>
                                            .
                                        </li>
                                        <li style={{ color: 'transparent' }}>
                                            .
                                        </li>
                                        <li style={{ color: 'transparent' }}>
                                            .
                                        </li>
                                    </ul>
                                    {
                                        localStorage.getItem("email") &&
                                        <Button className="redButtonTrial clearfix" onClick={() => onRegisterClick(3)}>Elegir</Button>
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className='price-card'>
                                    <p className='price-card-title-one-row'>
                                        SOY EMPRESA
                                    </p>
                                    <span className='price-card-subtitle'>
                                        Soy empresa, tengo dudas sobre casos de uso, pero quiero un proyecto ad hoc
                                    </span>
                                    <p className='price-card-price-text'>
                                        Consúltanos
                                    </p>
                                    <p className='price-card-list-title'>
                                        Funcionalidades
                                    </p>
                                    <ul className='price-card-list'>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Audios ilimitados
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Combinación voces
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Descargar audio
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Customizar player
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Voces Premium
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Sonidos
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Estadísticas en tiempo real
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Soporte dedicado
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Mantemimiento
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Integración a medida
                                        </li>
                                        <li style={{ backgroundImage: `url(${libg})` }}>
                                            Voces multi idioma
                                        </li>
                                    </ul>
                                    {
                                        localStorage.getItem("email") &&
                                        <Button className="redButtonTrial clearfix" onClick={onEnterpriseClick}>Actualizar</Button>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className='faqs'>
                    </div>
                </div>
            </Container>
            <Footer/>
        </ThemeProvider>
        </HelmetProvider>
    )
}
