import {
  Box,
  Grid,
  Button,
  Checkbox,
  Typography,
  TextField,
  FormControlLabel,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { getUser } from '../../utils/utils';
import { roles } from '../../utils/roles';
import { ReactComponent as Logo } from '../../assets/img/play-circle.svg'
import { ReactComponent as Timeline } from '../../assets/img/timeline.svg'
import { ReactComponent as PlayLogo } from '../../assets/img/play-last.svg';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { createAudioplayerDB, updatePlayer, getPlayerById } from '../../adapters/api';
const Swal = require('sweetalert2')

export const AudioPlayerEditor = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [playerId, setPlayerId] = useState('');
  const [color, setColor] = useState('#FB4859');
  const [frame, setFrame] = useState(true);
  const [watermark, setWatermark] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const { id } = useParams();
  let data = useLocation();
  const isUpdate = data.state ? data.state.update : false;
  useEffect(() => {
    async function fetchData() {
      if (isUpdate) {
        const data = await getPlayerById(id);
        if (data) {
          const player = data.data.player;
          setPlayerId(player._id);
          setTitle(player.title);
          setColor(player.color);
          setFrame(player.frame);
          setWatermark(player.watermark);
        }
      }
    }
    fetchData();
  }, [id, isUpdate])
  const handleFrame = () => { setFrame(!frame);  }
  const handleWatermark = () => {  setWatermark(!watermark); }
  const handleTitle = (e) => { setTitleError(false); setTitle(e.target.value); }
  const changeColorFromText = (e) => { if (isValidHex(e.target.value)) { setColor(e.target.value); } }
  const isValidHex = (color) => {
    if (!color || typeof color !== 'string') return false;
    // Validate hex values
    if (color.substring(0, 1) === '#') color = color.substring(1);
    switch (color.length) {
      case 3: return /^[0-9A-F]{3}$/i.test(color);
      case 6: return /^[0-9A-F]{6}$/i.test(color);
      case 8: return /^[0-9A-F]{8}$/i.test(color);
      default: return false;
    }
  }
  const saveAudioPlayer = async () => {
    if (title.trim().length) {
      const user = await getUser();  
      const player = {
        userId: user.data.user._id,
        title: title,
        color: color,
        frame: frame,
        watermark: watermark
      };
      try {
        const res = await createAudioplayerDB(player);
        if (res.status === 200) {
          Swal.fire({
            title: 'Éxito',
            text: 'Player creado satisfactoriamente',
            icon: 'success',
            confirmButtonText: 'Ok'
          });
          navigate('/dashboard/audioplayer');
        }
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Hubo un error durante la creación del player',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }
    } else {
      setTitleError(true);
    }
  }
  const updateAudioPlayer = async () => {
    const user = await getUser();
    const player = {
      playerId: playerId,
      userId: user.data.user._id,
      title: title,
      color: color,
      frame: frame,
      watermark: watermark
    };
    try {
      const res = await updatePlayer(player);
      if (res.status === 200) {
        Swal.fire({
          title: 'Éxito',
          text: 'Player actualizado satisfactoriamente',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
        navigate('/dashboard/audioplayer');
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Hubo un error durante la actualización del player',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  }
  return (
    <Box sx={{ width: '100%', marginLeft: 0, marginTop: 5, maxWidth: 700, textAlign: 'left' }}>
      <Typography className="menuTitle" style={{ marginBottom: 20 }}><PlayLogo /> Crear nuevo audio Audio Player</Typography>
      <Typography className="subTitle" variant="subtitle1" gutterBottom component="div">
        ¡Genial! Elige tu configuración favorita seleccionado el título que quieres poner o el color del player. 
        Además disponemos de opciones Premium más abajo que iremos mejorando con el paso del tiempo.
      </Typography>
      <Typography variant="h6" style={{ marginBottom: 30, marginTop: 30 }} component="h6">Previsualización</Typography>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="fakePlayer" style={{ borderColor: frame ? color : "transparent", marginLeft: 3 }}>
        <Grid container={true} item md={2} className="fakePlayerPlayButton" >
          <Logo style={{ width: '60px', height: '60px', marginLeft: 30 }} fill={color} />
        </Grid>
        <Grid container={true} item md={10} >
          <Box sx={{ width: '100%', maxWidth: 500, height: 98.9, textAlign: 'left' }}>
            <Typography style={{ fontWeight: 400, marginTop: 5, color: '#26022F', fontSize: 14, position: "absolute" }} >{title}</Typography>
            <Timeline style={{ width: '80px', height: '50px', marginLeft: -13, marginTop: 37, marginBottom: 1 }} fill={color} className="fakePlayerTimeline" />
            <Typography style={{ float: "right", marginTop: 64, marginRight: 45, color: '#26022F', fontSize: 14, }} >18:32</Typography>
          </Box>
        </Grid>
      </Grid>
      {!watermark && (
        <Typography variant="caption" className="watermark" gutterBottom component="div">
          Convierte lectores en oyentes con <a style={{ color: "red" }}><strong>Auris</strong></a>
        </Typography>)}
      <Typography variant="h6" component="h6">Configurador</Typography>
      <hr></hr>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom >Título del player</Typography>
          <TextField
            id="filled-basic"
            label="✍️ Escribe el título..."
            variant="filled"
            autoComplete="title"
            autoFocus
            value={title}
            onChange={handleTitle} 
            error={titleError? true : false }
            helperText={titleError? '*Obligatorio' : ''}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" gutterBottom >Color del player</Typography>
          <HexColorPicker color={color} onChange={setColor} />
          <TextField
            inputProps={{ maxLength: 7 }}
            id="filled-basic"
            label="Código color"
            variant="filled"
            autoComplete="color"
            autoFocus
            value={color}
            onChange={(event) => setColor(event.target.value)} 
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel control={<Checkbox checked={frame} onChange={handleFrame} />} label="Marco" />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel 
            label="Quitar marca de agua"
            control={
              <Checkbox 
                checked={watermark}
                disabled={parseInt(localStorage.getItem('tier')) === roles.basic}
                onChange={handleWatermark} 
              />
            }
          />
          <Typography display="block" gutterBottom component="div">
            Sólo para plan premium
          </Typography>
        </Grid>
      </Grid>
      <Button className="redButtonPlayerEditor" onClick={isUpdate? updateAudioPlayer : saveAudioPlayer}>Guardar</Button>
    </Box>
  )
}