import React, { useEffect, useState} from 'react';
import Link from '@mui/material/Link';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import Toolbar from '@mui/material/Toolbar';
import { ReactComponent as ConversationLogo } from '../../assets/img/conversation-last.svg';
import { ReactComponent as VolumeLogo } from '../../assets/img/volume-last.svg';
import { ReactComponent as PlayLogo } from '../../assets/img/play-last.svg';
import { ReactComponent as ShareLogo } from '../../assets/img/share-last.svg';
import { ReactComponent as IconMusic } from '../../assets/img/icon-music.svg';
import { ReactComponent as StatsLogo } from '../../assets/img/stats.svg';
import { ReactComponent as GearLogo } from '../../assets/img/gear.svg';
import Logo from '../../assets/img/logo.png'
import { getTiers, refreshToken, getUserTier, getAudioCounter, isUserSubscriptionIsActive } from '../../adapters/api';
import Swal from 'sweetalert2';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AudioCounter from '../../components/audioCounter/AudioCounter';

const drawerWidth = 271;

export const Dashboard = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [audioMaximum, setAudioMaximum] = useState(0);
  const [userAudioCont, setUserAudioCont] = useState(0);
  const [userEndPeriod, setUserEndPeriod] = useState(0);
  const { window } = props;
  
  useEffect(async () => {
    
    try {
      const { data } = await refreshToken();
      localStorage.setItem('auth-token', data.token);
    } 
    catch (error) {
      // todo: move to AuthService or something to be reused
      localStorage.clear();
      Swal.fire({
        title: 'Sesión expirada',
        text: 'Su sesión ha caducado. Haz click en el botón para ir al login',
        icon: 'warning',
        confirmButtonText: 'Login'
      }).then(() => {
        navigate('/login');
      })
    }

    const userTier = await getUserTier();
    if(userTier.data.tier === -1) { navigate('/tiers'); return; }
    const isSubscriptionActive = await isUserSubscriptionIsActive();
    if(!isSubscriptionActive.data){ navigate('/tiers'); return; }
    const audioCounter = await getAudioCounter();
    const responseTiers = await getTiers();
    setAudioMaximum(audioCounter.data.maximum);
    setUserAudioCont(audioCounter.data.count);
    setUserEndPeriod(audioCounter.data.endPeriod);
  },[])

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logout = async (event) => {
    event.preventDefault();
    localStorage.clear();
    navigate('/login');
  }

  const isMainPage = () => {
    let result = true;
    if(location.pathname === "/dashboard/subscriptions") result = false;
    return result;
  }

  const drawer = (
    <>
    <HelmetProvider>
      <table style={{
                height: "100%",
                width: "100%",
                borderCollapse: "collapse"
            }}>
        <tr>
          <td>
            <Helmet>
              <meta name="robots" content="noindex"></meta>
            </Helmet>
            <Toolbar className="aurisTitleDashboard">
              <Link href="/">
                <img src={Logo} width="138px" height="69px" alt="logo" style={{ verticalAlign: 'middle' }} />
              </Link>
            </Toolbar>
          </td>
        </tr>
        <tr>
          <td>
            <ListItem>
              <ListItemIcon className="sidebarIcon">
                <GearLogo />
              </ListItemIcon>
              <ListItemText className="sidebarItemTitle" primary='MI ESPACIO DE TRABAJO' />
            </ListItem>
            <ListItem selected={location.pathname === '/dashboard/audios'} button component={Link} href="/dashboard/audios">
              <ListItemIcon className="sidebarIcon">
                <ConversationLogo />
              </ListItemIcon>
              <ListItemText className="sidebarSubItemTitle" primary='Introduce tu texto' />
            </ListItem>
            <ListItem selected={location.pathname === '/dashboard/voices'} button component={Link} href="/dashboard/voices">
              <ListItemIcon className="sidebarIcon">
                <VolumeLogo />
              </ListItemIcon>
              <ListItemText className="sidebarSubItemTitle" primary='Voces' />
            </ListItem>
            <ListItem selected={location.pathname === '/dashboard/sounds'} button component={Link} href="/dashboard/sounds">
              <ListItemIcon className="sidebarIcon">
                <IconMusic />
              </ListItemIcon>
              <ListItemText className="sidebarSubItemTitle" primary='Sonidos' />
            </ListItem>
            <ListItem selected={location.pathname === '/dashboard/audioplayer'} button component={Link} href="/dashboard/audioplayer">
              <ListItemIcon className="sidebarIcon">
                <PlayLogo />
              </ListItemIcon>
              <ListItemText className="sidebarSubItemTitle" primary='Audio player' />
            </ListItem>
            <ListItem selected={location.pathname === '/dashboard/snippet'} button className="sidebarSubItemTitle" component={Link} href="/dashboard/snippet">
              <ListItemIcon className="sidebarIcon">
                <ShareLogo />
              </ListItemIcon>
              <ListItemText className="sidebarSubItemTitle" primary='JS Code Snippet' />
            </ListItem>

            {
              Number(localStorage.getItem("tier")) > 0 &&

              <ListItem style={{ marginTop: 20 }} selected={location.pathname === '/dashboard/stats'} button className="sidebarSubItemTitle" component={Link} href="/dashboard/stats">
                <ListItemIcon className="sidebarIcon">
                  <StatsLogo />
                </ListItemIcon>
                <ListItemText className="sidebarItemTitle" primary='ESTADÍSTICAS' />
              </ListItem>
            }

            <ListItem style={{ marginTop: 20 }} selected={location.pathname === '/dashboard/subscriptions'} button className="sidebarSubItemTitle" component={Link} href="/dashboard/subscriptions">
              <ListItemIcon className="sidebarIcon">
                <StatsLogo />
              </ListItemIcon>
              <ListItemText className="sidebarItemTitle" primary='FACTURACIÓN' />
            </ListItem>
          </td>
        </tr>
        <tr>
          <td>
            
              <ListItem button className="profile sectionTwoSidebar borderTop" >
                <ListItem className="sidebarSubItemTitle2" selected={location.pathname === '/dashboard/profile'} component={Link} href="/dashboard/profile">
                  <ListItemAvatar>
                    <Avatar>
                      <PersonOutlineIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={localStorage.getItem('username')}
                    secondary='Ir al perfil'
                  />
                </ListItem>
              </ListItem>

              <ListItem>
                <AudioCounter cont={userAudioCont} total={audioMaximum} showTitle={true} endPeriod={userEndPeriod}></AudioCounter>
              </ListItem>
              
              <ListItem button className="logout sectionTwoSidebar" component={Link} >
                <ListItem onClick={logout}>
                  <ListItemAvatar>
                    <Avatar>
                      <LogoutIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText className="sidebarSubItemTitle2"
                    primary="Cerrar sesión"
                  />
                </ListItem>
              </ListItem>
            
          </td>
        </tr>
      </table>
      </HelmetProvider>
    </>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {
        isMainPage() ?
        <Box
        component="main"
        className="auris-main-dashboard"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, paddingLeft: { sm: `190px` } }}
        >
          <Outlet context={{
            audioMaximum: audioMaximum, 
            setAudioMaximum: setAudioMaximum, 
            audioCont: userAudioCont, 
            setAudioCont: setUserAudioCont,
            userEndPeriod: userEndPeriod
          }} 
          />
        </Box>
        :
        <Box className="auris-dashboard">
          <Outlet context={{
            audioMaximum: audioMaximum, 
            setAudioMaximum: setAudioMaximum, 
            audioCont: userAudioCont, 
            setAudioCont: setUserAudioCont,
            userEndPeriod: userEndPeriod
          }}
          />
        </Box>
      }
    </Box>
  );
}