import {
  Box,
  Grid,
  Avatar,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { findOneUser, updateUser } from '../../adapters/api';
import { getUser } from '../../utils/utils';
const Swal = require('sweetalert2');

export const Profile = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
      async function fetchData() {
        const user = await findOneUser();
        setFirstName(user.data.user.firstName);
        setLastName(user.data.user.lastName);
        setEmail(user.data.user.email);
      }
      fetchData();
    }, [])

    const handleUpdateUser = async(event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const auxUser = await getUser();
      let user = {
          userId: auxUser.data.user._id,
          firstName: data.get('firstName'),
          lastName: data.get('lastName'),
          email: data.get('email'),
          password: data.get('password')
      };

      try {
        const res = await updateUser(user);
        if (res.status === 200) {
          Swal.fire({
            title: 'Éxito',
            text: 'Usuario actualizado satisfactoriamente',
            icon: 'success',
            confirmButtonText: 'Ok'
          });
        }
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Hubo un error durante la actualización del usuario',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }
    }

    return(
      <>
        <Box component="form" sx={{ marginLeft: 2, maxWidth: '900px', textAlign: 'center'}} onSubmit={handleUpdateUser}>
          <div className="tableTitle" style={{ marginLeft: 0 }}>
            <Typography className="menuTitle" variant="h4" component="h4">
              Perfil
            </Typography>
          </div>
          <Grid container className="profileFrame" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid container item md={2} style={{ display: 'inline-flex', alignItems: 'center' }}>
                <Avatar
                  alt=""
                  src=""
                  sx={{ width: 120, height: 120 }}
                />
                <Typography className="changePictureText"></Typography>
            </Grid>
            <Grid container item md={10} columnSpacing={3}>
              <Grid item xs={5} className="gridItem">
                <Typography className="textfieldTitle">Nombre</Typography>
                <TextField
                  className="profileTextField"
                  margin="dense"
                  required
                  id="firstName"
                  label="Nombre"
                  name="firstName"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  placeholder="Nombre"
                  defaultValue={'hola'}
                  autoFocus
                />
              </Grid>
              <Grid item xs={5} className="gridItem">
                <Typography className="textfieldTitle">Apellidos</Typography>
                <TextField
                  className="profileTextField"
                  margin="dense"
                  required
                  id="lastName"
                  label="Apellidos"
                  name="lastName"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  autoComplete="lastName"
                  autoFocus
                />
              </Grid>
              <Grid item xs={5}>
                <Typography className="textfieldTitle">Email</Typography>
                <TextField
                  className="profileTextField"
                  margin="dense"
                  required
                  id="email"
                  label="ejemplo@auris.com"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  autoComplete="email"
                  autoFocus
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item mt={5}>
              <Button
                className="gray-button"
                variant="contained"
                onClick={() => navigate(-1)}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item mt={5}>
              <Button
                className="red-button"
                type="submit"
                variant="contained"
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>
    )
}
