import * as React from 'react';
import { resetPassword } from '../../adapters/api';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../../App.css'
import Logo from '../../assets/img/logo.png'
import { Helmet, HelmetProvider } from 'react-helmet-async';
const Swal = require('sweetalert2');

function Copyright(props) {
  return (
    <div>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © Auris '}

        {new Date().getFullYear()}
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center" >
        <Link color="inherit" target="_blank" href="https://voikers.com/">
          Voikers
        </Link>{' & '}
        <Link color="inherit" target="_blank" href="https://avantgardeit.es/">
          Avantgarde IT
        </Link>
      </Typography>
    </div>

  );
}

const theme = createTheme();
export const ResetPassword = () => {
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      const res = await resetPassword(data.get('email'));
      Swal.fire({
        title: 'Éxito',
        text: 'Email enviado correctamente',
        icon: 'success',
        confirmButtonText: 'Ok'
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Error al intentar resetear contraseña',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  };


  return (
    <HelmetProvider>
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <div className='wrapper'>
        <div className='login'>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >

              <Typography component="h1" variant="h5" className="title">
                <Link href="/" variant="body2">
                  <img src={Logo} width={"138px"} height={"69px"} alt="logo" />
                </Link>
              </Typography>
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Correo electrónico"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <Box sx={{ textAlign: 'center' }}>
                  <Button
                    className="red-button"
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 3 }}
                  >
                    Enviar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </div>
        <Copyright sx={{ mt: 8, mb: 1 }} />
      </div>
    </ThemeProvider>
    </HelmetProvider>
  );
}


