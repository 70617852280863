import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { getJWTEmail, getToday, getYesterday, getLastWeek, getLastMonth, getLastYear } from '../../utils/utils';
import { Kpi} from '../../components/stats/Kpi';
import { Graph } from '../../components/stats/Graph';
import { DateFilter } from '../../components/stats/DateFilter';
import { AudioFilter } from '../../components/stats/AudioFilter';
import { ReactComponent as StatsLogo } from '../../assets/img/stats.svg';
import { getAudiosByUser, getAudiosLogs } from '../../adapters/api';

export const Stats = () => {
  const [audios, setAudios] = useState([]);
  const [logs, setLogs] = useState([]);
  const [average, setAverage] = useState(0);
  const [datasets, setDataSets] = useState([]);
  const [selectedDate, setSelectedDate] = useState('today');
  const [filterDates, setFilterDates] = useState(getToday());
  const [selectedAudios, setSelectedAudios] = useState([]);
  const hours = Array.from({length: 24}, (item, i) => { return i.toString() + 'h' });
  const [labels, setLabels] = useState(hours);
  const data = { labels, datasets: datasets };
  const [status, setStatus] = useState('');
  
  const handleDateChange = (event) => { 
    const value = event.target.value;
    const hours = Array.from({length: 24}, (item, i) => { return i.toString().padStart(2, '0') + 'h' });
    setSelectedDate(value);
    switch(value) {
      case 'today':
        setLabels(hours);
        setFilterDates(getToday());
        break;
      case 'yesterday':
        setLabels(hours);
        setFilterDates(getYesterday());
        break;
      case 'week':
        let week = [];
        const weekDates = getLastWeek();
        while(weekDates['startDate'] <= weekDates['endDate']) {
          week.push(moment(weekDates['startDate']).format('DD/MM/YYYY'));
          weekDates['startDate'] = moment(weekDates['startDate']).add(1, 'days');
        }
        setLabels(week);
        setFilterDates(getLastWeek());
        break;
      case 'month':
        let month = [];
        const monthDates = getLastMonth();
        while(monthDates['startDate'] <= monthDates['endDate']) {
          month.push(moment(monthDates['startDate']).format('DD/MM'));
          monthDates['startDate'] = moment(monthDates['startDate']).add(1, 'days');
        }
        setLabels(month);
        setFilterDates(getLastMonth());
        break;
      case 'year':
        const months = Array.from({length: 12}, (item, i) => {
          return new Date(0, i).toLocaleString('es-ES', { month: 'long' })
                               .replace(/^\w/, firstLetter => firstLetter.toUpperCase()) 
        });
        setLabels(months);
        setFilterDates(getLastYear());
        break;
      default:
        setLabels(hours);
        setFilterDates(getToday());
        break;
    }
  };
  
  const handleAudioChange = (event) => {  setSelectedAudios(event.target.value); }

  const groupByLabel = (data) => {
    const audio = [];
    data.forEach((log) => {
      audio[log.label] = audio[log.label] || { label: log.label, reps: 0, avrg: 0, playingTime: 0, duration: 0 };
      audio[log.label].reps += +log.reps;
      audio[log.label].playingTime += +log.playingTime;
      audio[log.label].duration += +log.duration;
      if (audio[log.label].duration !== 0) { audio[log.label].avrg = audio[log.label].playingTime / audio[log.label].duration; }
    });
    audio.forEach((audio) => { audio.avrg = (audio.avrg * 100).toFixed(0) });
    return audio;
  }

  useEffect(() => {
    async function fetchData() {
      const userEmail = getJWTEmail();
      const user = { email: userEmail }
      const audios = await getAudiosByUser(user);
      setAudios(audios.data.audios);
    }
    fetchData();
  }, [])

  useEffect(() => {
    async function filterLogs() {
      const audiosLogs = await getAudiosLogs({ audios: selectedAudios, dates: filterDates });
      setLogs(audiosLogs.data.result);
    }
    filterLogs();
  }, [filterDates, selectedAudios])

  useEffect(() => {
    function calcAverage() {
      if (logs.length) {
        const average = logs.reduce((total, next) => total + next.playingTime, 0) / logs.length;
        const duration = logs.reduce((total, next) => total + next.duration, 0)  / logs.length;
        const percentage = (average / duration) * 100;
        setAverage(percentage.toFixed(0));
      } else {
        setAverage(0);
      }
     }
    calcAverage();
  }, [logs])

  useEffect(() => {
    const graphColors = [
      '#FB4859', 
      '#FF908D', 
      '#E5A1D7', 
      '#26022F', 
      '#A1D7E6', 
      '#A1E6A5', 
      '#E6E5A1', 
      '#E6BCA1',
      '#FB4859', 
      '#FF908D'
    ];

    const groupByIdAudio = (logs) => {
      return logs.reduce(function (groups, log) {
        const logDay = moment(log.created);
        groups[log.idAudio] = groups[log.idAudio] || [];
        for (const label in labels) {
          if (selectedDate === 'today' && Number(label) === logDay.hour()) { 
            groups[log.idAudio].push({ label: label, reps: 1, avrg: 0, playingTime: log.playingTime, duration: log.duration });
          } else if (selectedDate === 'yesterday' && Number(label) === logDay.hour()) {
            groups[log.idAudio].push({ label: label, reps: 1, avrg: 0, playingTime: log.playingTime, duration: log.duration });
          } else if (selectedDate === 'week' && labels[label] === logDay.format('DD/MM/YYYY'))  {
            groups[log.idAudio].push({ label: label, reps: 1, avrg: 0, playingTime: log.playingTime, duration: log.duration });
          } else if (selectedDate === 'month' && labels[label] === logDay.format('DD/MM'))  {
            groups[log.idAudio].push({ label: label, reps: 1, avrg: 0, playingTime: log.playingTime, duration: log.duration });
          } else if (selectedDate === 'year' && Number(label) === logDay.month())  {
            groups[log.idAudio].push({ label: label, reps: 1, avrg: 0, playingTime: log.playingTime, duration: log.duration });
          } else {
            groups[log.idAudio].push({ label: label, reps: 0, avrg: 0, playingTime: 0, duration: 0 });
          }
        }
        return groups;
      }, Object.create(null));
    }
    
    function graphDataSets() {
      var i = 0;
      const datasets = [];
      const groupedAudios = groupByIdAudio(logs);
      const slicedAudios = Object.fromEntries(Object.entries(groupedAudios).slice(0, 10));
      const slicedAudiosKeys = Object.keys(slicedAudios);
      
      slicedAudiosKeys.forEach(function (key) {
        const title = audios.find(audio => audio._id === key).title;
        datasets.push({
          label: title,
          data: groupByLabel(slicedAudios[key]),
          borderColor: graphColors[i],
          backgroundColor: graphColors[i],
        });
        i++;
      });
      setDataSets(datasets);
      setStatus('ready');
    }
    graphDataSets();
  }, [average, labels, logs, selectedDate, audios, status])
  
  return (
    <>
      <Box sx={{ width: '100%', maxWidth: 500 }}>
        <div className="tableTitle">
          <Typography style= {{ color: 'gray' }} className="menuTitle" variant="h4" component="h4">
            <StatsLogo /> Estadísticas
          </Typography>
          <Typography className="subTitle" style={{maxWidth: 500}}>
            ¡Consulta las estadísticas de tus Snippets de un solo vistazo! Podrás conocer en tiempo real como
            reaccionan tus oyentes a cada audio.
          </Typography>
        </div>
        <Grid container ml={0} spacing={2.5} direction="row">
          <Grid item xs={6}>
            <AudioFilter value={selectedAudios} onChange={handleAudioChange} data={audios} />
          </Grid>
          <Grid item xs={6}>
            <DateFilter value={selectedDate} onChange={handleDateChange} /></Grid>
          <Grid item xs={6}>
            <Kpi title={'Total'} value={logs.length} legend={'número de reproducciones'} />
          </Grid>
          <Grid item xs={6}>
            <Kpi title={'Media'} value={average} legend={'porcentaje de escucha'} />
          </Grid>
        </Grid>
        <Grid container style={{ width: '750px', height: '350px' }} mt={0.5} mb={57} ml={-1} spacing={3}>
          <Grid item xs={12}>
            <Graph title="Número de reproducciones por audio" data={data} labels={labels} />
          </Grid>
          <Grid item xs={12}>
            <Graph average title="Porcentaje de escucha por audio" data={data} labels={labels} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}