import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import { getSounds } from '../../adapters/api';
import { ReactComponent as VolumeLogo } from '../../assets/img/volume-last.svg';
import SoundStyleChip from '../../components/soundStyleChip/SoundStyleChip';
import AurisMultiselect from '../../components/aurisMultiselect/AurisMultiselect';
import AurisPlayPause from '../../components/aurisPlayPause/AurisPlayPause';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };


  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export const Sounds = () => {
  const [page, setPage] = React.useState(0);
  const [sounds, setSounds] = useState([]);
  const [availableSoundStyles, setAvailableSoundStyles] = useState([]);
  const [selectedSoundStyles, setSelectedSoundStyles] = useState([]);
  const [selectedLoopOptions, setSelectedLoopOptions] = useState([]);
  const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(async () => {
    const data = await getSounds();
    setSounds(data.data.sounds);
  }, [])

  useEffect(() => {
    setRows(sounds
      .map(({_id, sample, tags, templateName, soundTemplateId, isElastic}) => {
        return {id: _id, sample, tags, name: templateName, soundTemplateId, isElastic}
      })
      .filter(({tags}) => selectedSoundStyles.length === 0 || tags.some(tag => selectedSoundStyles.includes(tag)))
      .filter(({isElastic}) => selectedLoopOptions.length === 0 || selectedLoopOptions.includes(isElastic))
      .sort((a, b) => (a.gender < b.gender ? -1 : 1))
    );
    setAvailableSoundStyles(sounds
      .flatMap(sound => sound.tags)
      .filter((tag, index, tagList) => index === tagList.indexOf(tag))
    );
  }, [sounds, selectedSoundStyles, selectedLoopOptions])


  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#F0F0F0',
      borderColor: 'dedac9',
      color: theme.palette.common.black,
      fontWeight: 600
    },
    [`&.${tableCellClasses.body}`]: {
      borderRadius: '25px',
      fontSize: 14,
    },
  }));

  const tableFilters = [
    {name: 'style', title:'Filtrar por estilo', selectedItems:selectedSoundStyles, setSelectedItems:setSelectedSoundStyles, availableItems:availableSoundStyles, renderValueFn: (value) => (<SoundStyleChip tag={value}></SoundStyleChip>) },
    {name: 'loop', title:'Filtrar por bucle', selectedItems:selectedLoopOptions, setSelectedItems:setSelectedLoopOptions, availableItems:[true, false], renderValueFn: (value) => value ? 'Sí' : 'No' }
  ]

  return (
    <TableContainer className="customTableDiv" component={Paper}>
      <div className="tableTitle">
        <Typography className="menuTitle"><VolumeLogo /> Sonidos</Typography>
        <Typography className="subTitle" style={{ maxWidth: 500, marginBottom: 50 }}>Explora entre nuestra colección de sonidos AI para complementar tus audios y darles ese toque personal. Usa los filtros para afinar la búsqueda. </Typography>
      </div>
      <div style={{display: 'flex', alignItems: 'flex-end', paddingLeft: '20px'}}>
        {tableFilters.map(filterProps => (
          <AurisMultiselect {...filterProps}></AurisMultiselect>
        ))}
      </div>
      <Table className="customTable --with-filters" aria-label="custom pagination table" style={{marginTop: '0px !important'}}>
        <TableHead>
          <TableRow>
            <StyledTableCell className="roundedHeaderLeft tableHeader">Sonido</StyledTableCell>
            <StyledTableCell className="tableHeader">Estilo</StyledTableCell>
            <StyledTableCell className="roundedHeaderRight tableHeader">Efecto bucle</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row) => (
            <TableRow key={row.templateName}>
              <TableCell style={{ textTransform: 'capitalize' }} className="tableHeader auris-table__cell--no-left-padding">
                <AurisPlayPause soundUrl={row.sample} width={32} height={32}></AurisPlayPause>
                {row.name}
              </TableCell>
              <TableCell align="left" className="tableHeader">
                {row.tags.map((tag) =>(<SoundStyleChip tag={tag}></SoundStyleChip>))}
              </TableCell>
              <TableCell align="left" className="tableHeader">
                {row.isElastic ? 'Sí' : 'No'}
              </TableCell>
            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={3}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}