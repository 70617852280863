import {
  Box,
  Paper,
  Button,
  Dialog,
  Tooltip,
  TableHead,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableRow,
  TableFooter,
  TableContainer,
  TablePagination,
} from '@mui/material';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Delete as DeleteIcon,
  LastPage as LastPageIcon,
  GetApp as GetAppIcon,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext} from "react-router-dom";
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { getAudiosByUser, deleteAudio } from '../../adapters/api';
import { ReactComponent as ConversationLogo } from '../../assets/img/conversation-last.svg';
import { getJWTEmail } from '../../utils/utils';
import AurisPlayPause from '../../components/aurisPlayPause/AurisPlayPause';
import AudioCounter from '../../components/audioCounter/AudioCounter';
import moment from 'moment';
const Swal = require('sweetalert2');

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const handleFirstPageButtonClick = (event) => { onPageChange(event, 0);  };
  const handleBackButtonClick = (event) => { onPageChange(event, page - 1); };
  const handleNextButtonClick = (event) => { onPageChange(event, page + 1); };
  const handleLastPageButtonClick = (event) => { onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1)); };
  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
export const Audios = () => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [audios, setAudios] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dialogDelete, setdialogDelete] = useState(false);
  const [idSelected, setId] = useState(null);
  const navigate = useNavigate();
  const context = useOutletContext();
  moment.locale('es');
  useEffect(() => {
    setRows(audios
      .map(({_id, createdDate, title, scriptId, url}) => ({ 
        id: _id,
        createdDate: moment(formatDate(createdDate)).add(1, 'h').format('DD-MM-YYYY hh:mm'),
        title,
        scriptId,
        url 
      }))
      .sort((a, b) => (new Date(b.createdDate) < new Date(a.createdDate))))
  }, [audios])

  useEffect(() => {
    async function fetchData() {
      const userEmail = getJWTEmail();
      const user = { email: userEmail }
      const audios = await getAudiosByUser(user);
      setAudios(audios.data.audios);
    }
    fetchData();
  }, [])

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const handleChangePage = (event, newPage) => { setPage(newPage); };

  const handleNewAudio = () =>{
    const max = context.audioMaximum;
    const audioCont = context.audioCont;
    if(audioCont >= max){
      Swal.fire({
        title: 'Información',
        html: 'Ya has alcanzado este mes el número máximo de audios. Puedes mejorar tu suscripción en la sección de <a href="/dashboard/subscriptions">facturación</a>',
        icon: 'info',
        confirmButtonText: 'Ok'
      });
    }
    else{
      navigate("/dashboard/audios/editor");
    }
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDownload = (id) => {
    const url = audios.find(audio => audio._id === id).url;
    window.location.href = url;
  }
  const handleDelete = (id) => {
    setdialogDelete(true);
    setId(id);
  } 
  const handleCancel = () => {
    setdialogDelete(false);
    setId(null);
  }  
  const handleOk = async () => {
    setdialogDelete(false);
    try {  
      const res = await deleteAudio(idSelected);
      const cont = context.audioCont - 1;
      context.setAudioCont(cont);
      if(res.status === 200){
        Swal.fire({
          title: 'Éxito',
          text: 'Audio borrado satisfactoriamente',
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          } 
        })
        setId(null);
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Hubo un error durante el borrado del audio',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }  
  }
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#F0F0F0',
      borderColor: '#000',
      color: theme.palette.common.black,
      fontWeight: 600
    },
    [`&.${tableCellClasses.body}`]: {
      borderRadius: '25px',
      fontSize: 14,
    },
  }));
  const formatDate = (date) => {
    date = date.split('T')
    const days = date[0]
    const hours = date[1]
    const format1 = days.split('-')
    const format2 = hours.split(':')
    const day = format1[0]
    const month = format1[1]
    const year = format1[2]
    const hour = format2[0]
    const minute = format2[1]
    return new Date(`${day}-${month}-${year} ${hour}:${minute}`)
  }
  return (
    <TableContainer component={Paper} className="customTableAudiosDiv">
      <div className="tableTitle">
        <Typography className="menuTitle"> <ConversationLogo className="titeIcon" /> Introduce el texto</Typography>
        <Typography className="subTitle" style={{ maxWidth: 600 }}>
          ¡Bienvenido! El primer paso es que insertes el texto que deseas convertir en voz en el siguiente panel. Copia y pégalo para comenzar. 
        </Typography>
      </div>
      {/*<Button variant="contained" className="tableRedButtonAudios" href="/dashboard/audios/editor" >Crear nuevo</Button>*/}
      <Button variant="contained" className="tableRedButtonAudios" onClick={handleNewAudio} >Crear nuevo</Button>
      <Table className="customTable" aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <StyledTableCell className="roundedHeaderLeft tableHeader">Título</StyledTableCell>
            <StyledTableCell className="tableHeader">Fecha de creación</StyledTableCell>
            <StyledTableCell className="roundedHeaderRight">
              <AudioCounter cont={context.audioCont} total={context.audioMaximum} showTitle={false}></AudioCounter>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row) => (
            <TableRow key={row.id}>
              <TableCell className="tableHeader">
                {row.title}
              </TableCell>
              <TableCell className="tableHeader">
                {row.createdDate}
              </TableCell>
              <TableCell>
                <div className="rowLinkDiv">
                  <AurisPlayPause soundUrl={row.url}></AurisPlayPause>
                  {Number(localStorage.getItem('tier')) > 1 && (
                    <Tooltip title="Descargar">
                      <IconButton onClick={() => handleDownload(row.id)}>
                        <GetAppIcon style={{ color: "#26022F" }} />
                      </IconButton >
                    </Tooltip>
                  )}
                  {
                    Number(localStorage.getItem('tier')) > 1 && 
                    <Tooltip title="Eliminar">
                      <IconButton onClick={() => handleDelete(row.id)} style={{ color: "#26022F" }}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </div>
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={3}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={dialogDelete}
      >
        <DialogTitle>Eliminar Audio</DialogTitle>
        <DialogContent >
          ¿Quiere eliminar este audio?
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>No</Button>
          <Button onClick={handleOk}>Sí</Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
}