import {
  Box,
  Button,
  Grid,
  Tooltip,
  IconButton,
  Typography,
  TextField,
  InputLabel,
  FormControl,
  Paper,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Snackbar,
  TextareaAutosize,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";
import {
  getVoicesByUser,
  previewAudio,
  getAudioById,
  getVoiceByAlias,
  getSounds,
  generateAudioUser,
  Summerize,
  //  Summerize
} from "../../adapters/api";
import React from "react";
import { Textarea } from "react";
import { useRef, useState, useEffect } from "react";
import {
  useParams,
  useNavigate,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import { ReactComponent as ConversationLogo } from "../../assets/img/conversation-last.svg";
import Star from "../../assets/img/star.svg";
import { roles } from "../../utils/roles";
import { translateAccents } from "../../utils/utils";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import FlagImage from "../../components/flagImage/FlagImage";
import AurisPlayPause from "../../components/aurisPlayPause/AurisPlayPause";
import DialogTextArea from "../../components/dialogTextArea/DialogTextArea";
import SpeakerFrame from "../../components/speakerFrame/SpeakerFrame";
import ColorPicker from "../../components/colorPicker/index";
import SSMLButton from "../../components/ssmlTags/SSMLButton.jsx";
import Grow from "@mui/material/Grow";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Swal = require("sweetalert2");
let colorPicker = new ColorPicker();

export const AudioEditor = (props) => {
  const [selectedProsody, setSelectedProsody] = useState("");
  const [selectedPause, setSelectedPause] = useState("");
  const [renderPause, setRenderPause] = useState("");
  const [renderProsody, setRenderProsody] = useState("");
  const [selectedSub, setSelectedSub] = useState("");
  const navigate = useNavigate();
  const message = useRef(null);
  const [voices, setVoices] = useState([]);
  const [title, setTitle] = useState("");
  const [selectedVoice, setVoice] = useState();
  const [speakers, setSpeakers] = useState([]);
  const [dialogs, setDialogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [audioChanged, setAudioChanged] = useState(false);
  const [sounds, setSounds] = useState([]);
  const [selectedSound, setSound] = useState("");
  const [addSound, setAddSound] = useState(false);
  const [open, setOpen] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [voiceError, setVoiceError] = useState(false);
  const [textError, setTextError] = useState(false);
  const [sections, setSections] = useState({});
  const context = useOutletContext();
  const { id } = useParams();
  const [summarizeText, setSummarizeText] = useState("");
  const [alreadySummarizedText, setAlreadySummarizedText] = useState("");
  let data = useLocation();
  const isUpdate = data.state ? data.state.update : false;
  useEffect(() => {
    async function fetchData() {
      const voices = await getVoicesByUser();
      setVoices(
        voices.data.voices.sort((a, b) => (a.alias < b.alias ? -1 : 1))
      );
      const sounds = await getSounds();
      setSounds(sounds.data.sounds);
      if (isUpdate) {
        const audio = await getAudioById(id);
      } else {
        if (id) {
          const response = await getVoiceByAlias(id);
          if (response) {
            setVoice(response.data.voice.alias);
          }
        }
      }
    }
    fetchData();
  }, [id, isUpdate]);
  const handleTitle = (e) => {
    setTitleError(false);
    setTitle(e.target.value);
  };
  const saveAudio = async () => {
    let text = "";
    let endSection = "</as:sub>";
    dialogs.forEach((i, index) => {
      text = `${text}${i.id}${i.text}${endSection}`;
    });
    ssmlOptions.pause.forEach((pause) => {
      text = text.replaceAll(pause.icon, pause.value);
    });
    ssmlOptions.prosody.forEach((prosody) => {
      text = text.replaceAll(prosody.icon, prosody.value);
    });
    text = text.replaceAll("</velocidad>", "</prosody>");
    text = `<as:section name="mainSection" soundsegment="main">${text}</as:section>`
    if (title.trim().length && speakers.length > 0 && text.trim().length) {
      try {
        setLoading(true);
        const selectedVoice = speakers[0].alias;
        const res = await generateAudioUser(
          text,
          selectedVoice,
          title,
          addSound ? selectedSound : null,
          sections
        );
        if (res.status === 200) {
          const count = context.audioCont + 1;
          context.setAudioCont(count);
          setLoading(false);
          colorPicker = new ColorPicker();
          Swal.fire({
            title: "Éxito",
            text: "Audio creado satisfactoriamente",
            icon: "success",
            confirmButtonText: "Ok",
          });
          navigate("/dashboard/audios");
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: "Hubo un error durante la creación del audio",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } else {
      if (!title.trim().length) {
        setTitleError(true);
      }
      if (selectedVoice === undefined) {
        setVoiceError(true);
      }
      if (!message.current.value.trim().length) {
        setTextError(true);
      }
    }
  };
  async function getSoundUrl() {
    let text = "";
    let endSection = "</as:sub>";
    dialogs.forEach((i) => {
      text = `${text}${i.id}${i.text}${endSection}`;
    });
    ssmlOptions.pause.forEach((pause) => {
      text = text.replaceAll(pause.icon, pause.value);
    });
    ssmlOptions.prosody.forEach((prosody) => {
      text = text.replaceAll(prosody.icon, prosody.value);
    });
    text = text.replaceAll("</velocidad>", "</prosody>");
    if (text !== "" && speakers.length > 0) {
      const selectedVoice = speakers[0].alias;
      try {
        setLoading(true);
        const { data } = await previewAudio(
          text,
          selectedVoice,
          addSound ? selectedSound : null,
          sections
        );
        setLoading(false);
        return data.audio.data.files[0].url.replace("https", "http");
      } catch (error) {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: "Hubo un error durante la creación del audio",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    }
  }
  const handleChangeSound = (event) => {
    setSound(event.target.value);
    setAudioChanged(true);
  };
  const handleChangeSpeaker = (event) => {
    const voice = event.target.value;
    voice.colorId = colorPicker.getRandonColor();
    //setSpeaker(voice);
    setSpeakers((oldArray) => [...oldArray, voice]);
    onSelectedSpeakerFrame(voice, colorPicker.getColorStyle(voice.colorId));
  };
  const handleAddSound = () => {
    setAddSound(!addSound);
    setAudioChanged(true);
  };
  const onSelectedSpeakerFrame = (voice, colorStyle) => {
    const filterByAlias = dialogs.filter((item) => {
      return item.alias === voice.alias;
    });
    const countFilterByAlias = filterByAlias ? filterByAlias.length : 0;
    const id = `${voice.alias}${countFilterByAlias}`;
    const order = dialogs.length;
    setDialogs((oldArray) => [
      ...oldArray,
      {
        id: `<as:sub name="${id}">`,
        alias: voice.alias,
        voice: voice,
        colorStyle: colorStyle,
        sectionId: id,
        order: order,
        text: "",
      },
    ]);
    setSections((prevObj) => ({ ...prevObj, [id]: { voice: voice.alias } }));
  };
  const onCloseSpeakerFrame = (alias, colorId) => {
    const removeSpeakerByAlias = speakers.filter((item) => {
      return item.alias !== alias;
    });
    const removeDialogsByAlias = dialogs.filter((item) => {
      return item.alias !== alias;
    });
    let updatedSections = {};
    Object.entries(sections)
      .filter((s) => {
        return s[1].voice !== alias;
      })
      .forEach((i) => {
        const obj = i[1];
        updatedSections[i[0]] = obj;
      });
    setSpeakers(removeSpeakerByAlias);
    setDialogs(removeDialogsByAlias);
    setSections(updatedSections);
    colorPicker.releaseColor(colorId);
  };
  const onSelectedSpeakerFrameChange = (oldVoice, newVoice) => {
    const oldVoiceAlias = oldVoice.alias;
    //Actualizar speakers
    let itemsKpeakers = [...speakers];
    const speakerIndex = itemsKpeakers.findIndex((i) => {
      return i.alias === oldVoiceAlias;
    });
    itemsKpeakers[speakerIndex].accent = newVoice.accent;
    itemsKpeakers[speakerIndex].alias = newVoice.alias;
    itemsKpeakers[speakerIndex].audioSample = newVoice.audioSample;
    itemsKpeakers[speakerIndex].createdDate = newVoice.createdDate;
    itemsKpeakers[speakerIndex].gender = newVoice.gender;
    itemsKpeakers[speakerIndex].language = newVoice.language;
    itemsKpeakers[speakerIndex].type = newVoice.type;
    itemsKpeakers[speakerIndex].voiceName = newVoice.voiceName;
    setSpeakers(itemsKpeakers);
    //Actualizar dialogos
    let itemsDialogs = [...dialogs];
    dialogs
      .filter((i) => {
        return i.alias === oldVoiceAlias;
      })
      .forEach((dialog) => {
        const dialogIndex = itemsDialogs.findIndex((i) => {
          return i.id === dialog.id;
        });
        itemsDialogs[dialogIndex].id = itemsDialogs[dialogIndex].id.replace(
          oldVoiceAlias,
          newVoice.alias
        );
        itemsDialogs[dialogIndex].sectionId = itemsDialogs[
          dialogIndex
        ].sectionId.replace(oldVoiceAlias, newVoice.alias);
        itemsDialogs[dialogIndex].alias = newVoice.alias;
        itemsDialogs[dialogIndex].voice.accent = newVoice.accent;
        itemsDialogs[dialogIndex].voice.alias = newVoice.alias;
        itemsDialogs[dialogIndex].voice.audioSample = newVoice.audioSample;
        itemsDialogs[dialogIndex].voice.createdDate = newVoice.createdDate;
        itemsDialogs[dialogIndex].voice.gender = newVoice.gender;
        itemsDialogs[dialogIndex].voice.language = newVoice.language;
        itemsDialogs[dialogIndex].voice.type = newVoice.type;
        itemsDialogs[dialogIndex].voice.voiceName = newVoice.voiceName;
      });
    setDialogs(itemsDialogs);
    //Update Sections
    let itemsSections = {};
    dialogs.forEach((dialog) => {
      itemsSections[dialog.sectionId] = { voice: dialog.alias };
    });
    setSections(itemsSections);
  };
  const onCloseDialog = (id) => {
    const dialogsFilter = dialogs.filter((item) => {
      return item.id !== id;
    });
    let updatedSections = {};
    Object.entries(sections)
      .filter((s) => {
        return s[0] !== id;
      })
      .forEach((i) => {
        const obj = i[1];
        updatedSections[i[0]] = obj;
      });
    setDialogs(dialogsFilter);
    setSections(updatedSections);
  };
  const onDialogChange = (id, text, order) => {
    const dialog = dialogs.find((item) => {
      return item.id === id;
    });
    dialog.text = text;
    console.log(dialog.text);
    dialog.order = order;
    setDialogs((oldArray) => dialogs);
    setSelectedPause("");
    setSelectedProsody("");
    setSelectedSub("");
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  function userSelect(type, icon, selected) {
    if (type.toString().toLowerCase() === "pause".toString().toLowerCase()) {
      selected.preventDefault();
      setSelectedPause(selected.target.value);
      setRenderPause(icon);
    } else if (
      type.toString().toLowerCase() === "prosody".toString().toLowerCase()
    ) {
      selected.preventDefault();
      setSelectedProsody(selected.target.value);
      setRenderProsody(icon);
    } else if (
      type.toString().toLowerCase() === "sub".toString().toLowerCase()
    ) {
      selected.preventDefault();
      setSelectedSub(selected.target.value);
    }
  }

  const ssmlOptions = {
    pause: [
      {
        type: "pause",
        name: ",",
        value: '<break time="1s"/>',
        icon: " <coma>",
        tooltipTitle: "Pausa 1s"
      },
      {
        type: "pause",
        name: ".",
        value: '<break time="2s"/>',
        icon: "<punto>",
        tooltipTitle: "Pausa 2s"
      },
      {
        type: "pause",
        name: " ¶",
        value: '<break time="3s"/>',
        icon: "<párrafo>",
        tooltipTitle: "Pausa 3s"
      },
    ],
    prosody: [
      {
        type: "prosody",
        name: "x 0.5",
        value: "<prosody rate='slow'>",
        icon: "<lento>",
        tooltipTitle: "Velocidad x0.5"
      },
      {
        type: "prosody",
        name: "x 2",
        value: "<prosody rate='fast'>",
        icon: "<rápido>",
        tooltipTitle: "Velocidad x2"
      },
      {
        type: "prosody",
        name: "x 4",
        value: "<prosody rate='x-fast'>",
        icon: "<mas rápido>",
        tooltipTitle: "Velocidad x4"
      },
    ],
    sub: [
      {
        type: "sub",
        name: "abrev.",
        value: `<sub alias=${"'Escribe aqui la abreviatura'"}>`,
        tooltipTitle: "Abreviatura"
      },
    ],
  };

  const [showTextAreas, setShowTextAreas] = useState(false);

  const handleButtonClick = () => {
    setShowTextAreas(!showTextAreas);
  };
  const summerizeAreaClosed = {
    height: "90px",
  };
  const summerizeAreaOpened = {
    height: "300px",
  };

  const handleSummarize = async () => {
    setLoading(true);
    const result = await Summerize(summarizeText);
    setAlreadySummarizedText(result.data.result);
    setLoading(false);
  };

  const handleChangeTextField = (event) => {
    setSummarizeText(event.target.value);
  };

  const handleClickSnackbar = () => {
    setOpen(true);
  };

  return (
    <Box sx={{ width: "100%", marginLeft: 0, marginTop: 5, textAlign: "left" }}>
      <Typography
        sx={{ maxWidth: 600 }}
        className="menuTitle"
        style={{ marginBottom: 20 }}
      >
        <ConversationLogo className="titeIcon" /> Crear nuevo texto
      </Typography>
      <Typography sx={{ maxWidth: 600 }} className="subTitle">
        Ahora solo tienes que seleccionar el título de tu audio para poder
        identificarlo más adelante. Después añade el texto que quieras y
        selecciona una voz de todas las disponibles. Cuando lo tengas listo,
        podrás seleccionarlo junto con un Player para crear un Snippet.
      </Typography>
      <Grid
        container
        spacing={2}
        style={showTextAreas ? summerizeAreaOpened : summerizeAreaClosed}
      >
        <Grid item>
          <Button
            className="redButtonPlayerEditorBig"
            color="primary"
            onClick={handleButtonClick}
          >
            {showTextAreas ? "Ocultar resumen" : "¿Necesitas un resumen?"}
          </Button>
        </Grid>
        {
          <>
            <Grow
              in={showTextAreas}
              style={{ transformOrigin: "25% 0 0" }}
              {...(showTextAreas ? { timeout: 500 } : { timeout: 50 })}
            >
              <Grid item xs={12}>
                <Paper>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Introduce el texto..."
                        multiline
                        minRows={4}
                        maxRows={4}
                        fullWidth
                        variant="outlined"
                        value={summarizeText}
                        onChange={handleChangeTextField}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Resumen"
                        multiline
                        minRows={4}
                        maxRows={4}
                        fullWidth
                        variant="outlined"
                        value={alreadySummarizedText}
                        style={{ backgroundColor: "#f0f0f0" }}
                      />
                      <CopyToClipboard
                        text={alreadySummarizedText}
                        onCopy={() => handleClickSnackbar()}
                      >
                        <Button style={{ color: "#26022F" }}>
                          <ContentCopyIcon className="copyIcon" />
                          Copiar
                        </Button>
                      </CopyToClipboard>
                    </Grid>
                  </Grid>
                </Paper>
                <Button
                  className="redButtonPlayerEditor"
                  onClick={handleSummarize}
                >
                  Resumir
                </Button>
              </Grid>
            </Grow>
          </>
        }
      </Grid>
      <Grid container>
        <Grid sx={{ maxWidth: 600 }} item xs={12}>
          <Typography
            variant="subtitle1"
            gutterBottom
            style={{ marginTop: 40 }}
          >
            Título del texto
          </Typography>
          <TextField
            id="audio-title"
            className="AudioTitleInput"
            label="✍️ Escribe el título..."
            variant="filled"
            onChange={handleTitle}
            error={titleError ? true : false}
            helperText={titleError ? "*Obligatorio" : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={addSound}
                disabled={
                  parseInt(localStorage.getItem("tier")) === roles.basic
                }
                onChange={handleAddSound}
              />
            }
            label="Añadir sonido"
          />
          {parseInt(localStorage.getItem("tier")) === roles.basic ? (
            <Typography display="block" gutterBottom component="div">
              Sólo para plan premium
            </Typography>
          ) : null}
          <FormControl
            variant="outlined"
            style={{ width: "100%", display: addSound ? "" : "none" }}
          >
            <InputLabel
              style={{ marginLeft: 5, backgroundColor: "white", padding: 5 }}
              id="label-placeholder-player"
            >
              Elige un sonido
            </InputLabel>
            <Select
              className="soundSelect"
              style={{ width: 500 }}
              value={selectedSound ? selectedSound : ""}
              onChange={handleChangeSound}
            >
              <MenuItem disabled value="">
                <em>Elige un sonido</em>
              </MenuItem>
              {sounds.map((sound) => (
                <MenuItem
                  key={sound.soundTemplateId}
                  value={sound.soundTemplateId}
                >
                  {sound.templateName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid sx={{ paddingTop: "10px" }} container>
          <Grid sx={{ paddingRight: "20px" }} item xs={7}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel
                style={{
                  marginLeft: 5,
                  backgroundColor: "white",
                  padding: 5,
                  color: voiceError ? "#D32F2F" : "black",
                }}
                id="label-placeholder-player-NO"
              >
                {speakers.length > 0
                  ? "Añadir otra voz"
                  : voiceError
                    ? "Elige una voz.. la necesitamos"
                    : "Elige una voz"}
              </InputLabel>
              <Select
                className="voiceSelect"
                style={{ width: "inherit" }}
                value=""
                onChange={handleChangeSpeaker}
              >
                <MenuItem disabled value="">
                  <em>Elige una voz</em>
                </MenuItem>
                {voices.map((voice) => (
                  <MenuItem key={voice.alias} value={voice}>
                    <Tooltip
                      title={translateAccents(voice.accent)}
                      placement="right"
                    >
                      <span style={{ textTransform: "capitalize" }}>
                        <img
                          style={{
                            display: voice.type === "premium" ? "" : "none",
                          }}
                          src={Star}
                          width={"15px"}
                          height={"15px"}
                          alt={voice.type}
                        />
                        {voice.alias} -{" "}
                        {voice.gender === "female" ? "Femenino" : "Masculino"} -
                        <FlagImage
                          style={{
                            marginTop: "3px",
                            marginLeft: "5px",
                            position: "absolute",
                          }}
                          flag={voice.accent}
                          accent={voice.accent}
                          width={"20px"}
                          height={"20px"}
                        />
                      </span>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{ position: "relative", width: "100%" }}>
              <DialogTextArea
                dialogs={dialogs}
                textRef={message}
                onDialogChange={onDialogChange}
                onDialogClose={onCloseDialog}
                selectedUserPause={selectedPause}
                selectedUserProsody={selectedProsody}
                selectedUserSub={selectedSub}
                selectedPauseIcon={renderPause}
                selectedProsodyIcon={renderProsody}
              ></DialogTextArea>
              <div
                style={{
                  position: "absolute",
                  top: "5px",
                  left: "5px",
                  borderBottom: "2px solid black",
                  width: "98%",
                }}
              >
                <div style={{ display: "flex" }}>
                  {ssmlOptions.pause.map((option, index) => (
                    <SSMLButton
                      handleClick={userSelect}
                      tooltipTitle={option.tooltipTitle}
                      icon={option.icon}
                      key={index}
                      name={option.name}
                      value={option.value}
                      type={option.type}
                    />
                  ))}
                  {ssmlOptions.prosody.map((option, index) => (
                    <SSMLButton
                      handleClick={userSelect}
                      tooltipTitle={option.tooltipTitle}
                      icon={option.icon}
                      key={index}
                      name={option.name}
                      value={option.value}
                      type={option.type}
                    />
                  ))}
                  {ssmlOptions.sub.map((option, index) => (
                    <SSMLButton
                      key={index}
                      tooltipTitle={option.tooltipTitle}
                      name={option.name}
                      type={option.type}
                      value={option.value}
                      handleClick={userSelect}
                    />
                  ))}
                  <div style={{ marginLeft: "auto" }}>
                    <AurisPlayPause
                      getSoundUrl={getSoundUrl}
                      width={32}
                      height={32}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid sx={{ paddingRight: "20px" }} item xs={5}>
            <div style={{ paddingTop: "10px" }}>
              {speakers.map((speaker, index) => (
                <SpeakerFrame
                  key={`${speaker.alias}${index}`}
                  index={index}
                  voice={speaker}
                  voices={voices}
                  borderColor={colorPicker.getBorderColor(speaker.colorId)}
                  backgroundColor={colorPicker.getBackgroundColor(
                    speaker.colorId
                  )}
                  onSelectClick={onSelectedSpeakerFrame}
                  onCloseClick={onCloseSpeakerFrame}
                  onChangeVoice={onSelectedSpeakerFrameChange}
                ></SpeakerFrame>
              ))}
            </div>
          </Grid>
        </Grid>
        <LoadingSpinner isLoading={loading} />
      </Grid>
      <Button className="redButtonPlayerEditor" onClick={saveAudio}>
        Guardar
      </Button>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Etiqueta copiada"
        action={action}
      />
    </Box>
  );
};
