import '../../App.css';
import React from 'react';
import { Link, Box, Typography } from '@mui/material';
import { Copyright } from '../../components/footer/copyright';

export const Footer = () => {
    const isUserLoged = () => {
        return localStorage.getItem("email") ?  true : false;
    }
    return (
        <>
            <hr className="footer-line"></hr>
            <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
                <Typography
                    variant="h6"
                    style={{ display: 'flex', justifyContent : 'center', gap: 50 }}
                    gutterBottom
                >
                    <Link color="inherit" underline="none" href="/voices">Voces</Link>
                    {
                        isUserLoged() ?
                        <Link color="inherit" underline="none" href="/dashboard/subscriptions">Precios</Link>
                        :
                        <Link color="inherit" underline="none" href="/tiers">Precios</Link>
                    }
                    <Link color="inherit" underline="none" href="/contact">Contacto</Link>
                </Typography>
                <Copyright sx={{ mt: 8, mb: 1 }} />
            </Box>
        </>
    )
}
