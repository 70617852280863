import React from 'react';
import { TextArea } from '../../components/textArea';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

export const Home = () => {

    const isUserLoged = () => {
        return localStorage.getItem("email") ?  true : false;
    }

    return (
        <div className='wrapper'>
            <Grid container spacing={2} className='pt-dk-10'>
                <Grid item xs={12} md={6} >
                    <div className="titleHome">
                        <span>Convertimos lectores en </span>
                        <span style={{color: "#e5a1d7"}}>oyentes</span>
                    </div>
                    <div className="subtitleHome">Facilita a tu audiencia el consumir tu contenido como quieran. Pasa
                        tu texto a audio en minutos mediante voces AI.
                    </div>
                    {
                        !isUserLoged() && <Button className="redButtonTrial" href="/register">PRUEBA AURIS</Button>
                    }
                </Grid>
                
                <Grid item xs={12} md={6}>
                    <TextArea landingMode="true"></TextArea>
                </Grid>
                
            </Grid>
        </div>
    )
}