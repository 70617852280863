import { 
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import React from 'react';

export const AudioFilter = (props) => {
  return (
    <>
      <FormControl variant='filled'>
        <InputLabel id="audio-filter-label">Audio</InputLabel>
        <Select
          multiple
          id="audio-filter"
          value={props.value}
          onChange={props.onChange}
          style={{ width: 240 }}
          renderValue={values => {
            const titles = [];
            props.data.forEach((audio) => {
              values.forEach((value) => {
                if (audio._id === value) {
                  titles.push(audio.title);
                }
              });
            });
            return titles.join(', ');
          }}
        >
          {props.data.map((audio) => (
            <MenuItem 
              key={audio._id} 
              value={audio._id}
              name={audio.title}
            >
                <span>{audio.title}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}