
export default class ColorPicker{
    
    constructor(){

        this.panelColors = [
            {id: 1, border: "blue", background: "#cbcbf5"},
            {id: 2, border: "green", background: "#cbf5cc"},
            {id: 3, border: "yellow", background: "#f4f5cb"},
            {id: 4, border: "orange", background: "#f5e1cb"},
            {id: 5, border: "red", background: "#f5cbcb"},
            {id: 6, border: "purple", background: "#f5cbf4"},
            {id: 7, border: "black", background: "white"}
        ];

        this.usedColors = [];
    }

    countAvailableColor = () => {
        return this.panelColors.length;
    }
    
    getBorderColor = (id) => {
        const color = this.panelColors.find((i)=>{return i.id === id});
        return color ? color.border : "";
    }

    getBackgroundColor = (id) => {
        const color = this.panelColors.find((i)=>{return i.id === id});
        return color ? color.background : "";
    }

    getColorStyle = (id) => {
        const color = this.panelColors.find((i)=>{return i.id === id});
        return { 
            borderColor: color.border, 
            backgroundColor: color.background
        };
    }
    
    getRandonColor = () => {
        let result = 0;
        const freeColor = this.panelColors.find(i => !this.usedColors.includes(i.id));
        if(freeColor){
            this.usedColors.push(freeColor.id);
            result = freeColor.id;
        }
        return result;
    }

    releaseColor = (id) => {
        for( var i = 0; i < this.usedColors.length; i++){ 
            if ( this.usedColors[i] === id) { 
                this.usedColors.splice(i, 1); 
            }
        }
    }


}