import './LoadingSpinner.css'

export default function LoadingSpinner(props) {
    if (props.isLoading) {
        return <div className="auris-loading-spinner">
            <svg className="auris-logo" viewBox="0 0 600 600">
                <linearGradient id="auris-logo__gradient"  y1="253.517" y2="253.517" gradientUnits="userSpaceOnUse" x1="155.017" x2="444.981">
                    <stop offset=".001" stopColor="#fd4655"/>
                    <stop offset=".133" stopColor="#df3c50"/>
                    <stop offset=".736" stopColor="#5b1139"/>
                    <stop offset="1" stopColor="#260030"/>
                </linearGradient>
                <path className="auris-logo__inner-u" fill="url(#auris-logo__gradient)" d="M300.458 437.099c-23.637 0-58.383-5.209-89.689-30.021-25.433-20.148-55.752-49.578-55.752-123.375V69.935h103.119v213.768c0 18.016 2.895 31.705 16.705 42.645 8.043 6.379 18.369 7.715 25.617 7.715 13.672 0 23.95-4.486 30.536-13.326 7.214-9.691 10.87-15.455 10.87-37.033V69.935h103.117v213.768c0 44.846-10.227 70.291-31.264 98.547-25.968 34.862-67.249 54.849-113.259 54.849z"/>
                <path className="auris-logo__outer-u" fill="#ff8e8a" d="M300.458 530.593c-38.597 0-95.624-8.738-148.16-50.379-41.68-33.02-91.373-85.68-91.373-197.039V69.407h95.382v213.768c0 40.451 9.588 86.18 55.253 122.361 31.023 24.586 65.464 29.746 88.898 29.746 45.6 0 86.506-19.801 112.224-54.332 20.867-28.021 31.011-53.217 31.011-97.775V69.407h95.383v213.768c0 65.801-16.311 109.609-49.873 154.676-43.901 58.941-112.698 92.742-188.745 92.742z"/>
            </svg>
        </div>
    } else {
        return (null);
    }
}