import './SpeakerTextArea.css';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import React, { useRef, useState, useEffect } from 'react';
import Star from '../../assets/img/star.svg';
import FlagImage from '../../components/flagImage/FlagImage';
import {IconButton,} from '@mui/material';
import {Close as CloseIcon} from '@mui/icons-material';

export default function SpeakerTextArea({id,voice,colorStyle,order=0,text, onChange, onClose, selectedPause, selectedProsody, selectedSub, renderPause, renderProsody}){
    const [positionClicked, setPositionClicked] = useState(null);

    const refTextArea = useRef(text);
    const placeholderText = `Escribe un texto para ${voice.alias}`;

    const insertSub = (event, text, ssml, positionS, positionE) => {
        text = refTextArea.current.value;
        ssml = selectedSub;
        positionS = refTextArea.current.selectionStart;
        positionE = refTextArea.current.selectionEnd;
        if (text!==undefined) {
            let final1 = text.slice(0, positionE +1) + '</sub>' + text.slice(positionE + 1)
            let final = final1.slice(0, positionS) + ssml + final1.slice(positionS)
            event.target.value=final;
            setPositionClicked(null)
            onChange(id, event.target.value, order)
        }
        setPositionClicked(null)
    }

    const insertProsody = (event, text, ssml, positionS, positionE) => {
        text = refTextArea.current.value;
        ssml = renderProsody;
        positionS = refTextArea.current.selectionStart;
        positionE = refTextArea.current.selectionEnd;
        if (text!==undefined) {
            let final1 = text.slice(0, positionE +1) + '</velocidad>' + text.slice(positionE + 1)
            let final = final1.slice(0, positionS) + ssml + final1.slice(positionS)
            event.target.value=final;
            setPositionClicked(null)
            onChange(id, event.target.value, order)
        }
        setPositionClicked(null)
    }
        
    const insertPause = (event, text, ssml, position) => {
        text = refTextArea.current.value;
        ssml = renderPause;
        position = refTextArea.current.selectionStart;
        if (text!==undefined) {
            const final = text.slice(0, position) + ssml + text.slice(position)
            event.target.value=final;
            setPositionClicked(null)
            onChange(id, event.target.value, order)
        }
        setPositionClicked(null)
    }
    
    const insertSSML = (event, text, ssml, positionS, positionE)=>{
        if(selectedPause!=="" && selectedProsody==="" && selectedSub===""){
            return insertPause(event, text, ssml, positionS)
        }else if(selectedProsody!=="" && selectedPause==="" && selectedSub===""){
            return insertProsody(event, text, ssml, positionS, positionE)
        }else if(selectedSub!=="" && selectedPause==="" && selectedProsody===""){
            return insertSub(event, text, ssml, positionS, positionE)
        }
    }
    return (<>
        <div>
        </div>
        <div className="auris-frame-textarea">
            <div className="auris-frame-textarea-title" style={colorStyle}>
                <img
                    style={{ display: voice.type === 'premium' ? '' : 'none' }}
                    src={Star}
                    width={'15px'}
                    height={'15px'}
                    alt={voice.type}
                />
                <span style={{ textTransform: 'capitalize' }}>
                    {voice.alias} - {voice.gender === 'female' ? 'Femenino' : 'Masculino'} -
                </span>
                <span>
                    <FlagImage
                        style={{ marginLeft: '5px' }}
                        flag={voice.accent}
                        accent={voice.accent}
                        width={'20px'}
                        height={'20px'}
                    />
                </span>
                <span style={{ float: "right", height: "30px" }}>
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => { onClose(id) }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </span>
            </div>
            <div className="auris-frame-textarea-body" style={colorStyle}>
                <TextareaAutosize
                    key={`TextareaAutosize${id}`}
                    className="TextareaAutosize"
                    aria-label="empty textarea"
                    placeholder={placeholderText}
                    ref={refTextArea}
                    defaultValue={text}
                    onChange={() => { onChange(id, refTextArea.current.value, order) }}
                    onMouseUp={(e, text, ssml, positionS, positionE) => { insertSSML(e, text, ssml, positionS, positionE) }}
                />
            </div>
        </div>
    </>);
}