import {
  Title,
  Legend,
  Tooltip,
  TimeScale,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale,
  Chart as ChartJS,
} from 'chart.js';
import React from 'react';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  Title,
  Legend,
  Tooltip,
  TimeScale,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale,
);

export const Graph = (props) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom'
      },
      title: {
        display: true,
        text: props.title,
        font: {
          size: 19,
          weight: 500,
          family: 'Gibson'
        },
      },
      tooltip: {
        callbacks: {
          title: function(context) {
            let title = props.labels[context[0].label];
            return title;
          },
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) { label += ': '; }
            if (props.average && context.parsed.y !== null) {
              label += context.parsed.y + '%';
            } else {
              label += context.parsed.y;
            }
            return label;
          }
        }
      }
    },
    scales: {
      x: {
        type: 'linear',
        ticks: {
            suggestedMin: 0,
            suggestedMax: 23,
            stepSize: 1,
            callback: function(value, index, ticks) {
              return props.labels[value]; 
            }
        },
        grid: {
          display: false
        }
      },
      y: {
        ticks: {
          stepSize: 1
        },
        beginAtZero: true,
        grid: {
          display: false
        }
      },
    },
    parsing: {
      xAxisKey: 'label',
      yAxisKey: props.average? 'avrg' : 'reps' 
    },
    elements: {
      line: {
        borderJoinStyle: 'round'
      }
    }
  };

  return (
    <Line options={options} data={props.data} />
  )
}